export const footerLinks = [
  {
    name: 'Home',
    nameRtl: 'الصفحة الرئيسية',
    url: '#home',
  },
  {
    name: 'Company',
    nameRtl: 'شركة',
    url: '#company',
  },
  {
    name: 'Blog',
    nameRtl: 'مدونة',
    url: '#blog',
  },
];

export const copyright = {
  companyHomeUrl: '#home',
  companyName: 'Horizon.ai',
  companyNameRlt: 'توقيت الإبداعية',
  slogan: ', made with love for a better you',
  sloganRlt: ', مصنوعة مع الحب لشبكة الإنترنت أفضل',
};
