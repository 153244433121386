/*
 * A very common scenario is you have a bunch of components that need to render different
 * depending on whether the current user is logged in and sometimes call authentication
 * methods like signIn, signOut, sendPasswordResetEmail, etc.
 *
 * This is a perfect use-case for a useAuth hook that enables any component to get the
 * current auth state and re-render if it changes. Rather than have each instance of the
 * useAuth hook fetch the current user, the hook simply calls useContext to get the data
 * from farther up in the component tree. The real magic happens in our <ProvideAuth>
 * component and our useProvideAuth hook which wraps all our authentication methods (in
 * this case we're using Firebase) and then uses React Context to make the current auth
 * object available to all child components that call useAuth. Whew, that was a mouthful...
 *
 * Hopefully as you read through the code below it should all make sense. Another reason
 * I like this method is it neatly abstracts away our actual auth provider (Firebase),
 * making it super easy to change providers in the future.
 *
 * Usage:
 *   // Top level App component
 *   import React from "react";
 *   import { ProvideAuth } from "./use-auth.js";
 *
 *   function App(props) {
 *     return (
 *       <ProvideAuth>
 *         {\*
 *           Route components here, depending on how your app is structured.
 *           If using Next.js this would be /pages/_app.js
 *         *\}
 *       </ProvideAuth>
 *     );
 *   }
 *
 *   // Any component that wants auth state
 *   import React from "react";
 *   import { useAuth } from "./use-auth.js";
 *
 *   function Navbar(props) {
 *     // Get auth state and re-render anytime it changes
 *     const auth = useAuth();
 *     return (
 *       <NavbarContainer>
 *         <Logo />
 *         <Menu>
 *           <Link to="/about">About</Link>
 *           <Link to="/contact">Contact</Link>
 *           {auth.user ? (
 *             <Fragment>
 *               <Link to="/account">Account ({auth.user.email})</Link>
 *               <Button onClick={() => auth.signOut()}>Sign out</Button>
 *             </Fragment>
 *           ) : (
 *             <Link to="/sign-in">Sign in</Link>
 *           )}
 *         </Menu>
 *       </NavbarContainer>
 *     );
 *   }
 *
 * Also check out:
 *
 *   - useSubmit - https://medium.com/javascript-in-plain-english/react-custom-hook-useonesubmit-b10be17245d8
 *     > Original hook by Murat Catal that inspired this recipe
 *
 *   - SWR - https://swr.now.sh/
 *      > A React Hooks library for remote data fetching. Similar concept, but includes caching, automatic refetching, and many other nifty features.
 *
 *   - react-async - https://github.com/async-library/react-async
 *      > React component and hook for declarative promise resolution and data fetching.
 */

import React, { useState, useEffect, useContext, createContext } from 'react';
import firebase from 'firecore/firebase.core';
// import firebase from 'firebase/app';
// import 'firebase/auth';

// // Add your Firebase credentials
// firebase.initializeApp(
//   {
//     apiKey: '',
//     authDomain: '',
//     projectId: '',
//     appID: '',
//   },
//   'use-auth.hook',
// );

export type AuthContext = ReturnType<typeof useProvideAuth>;
export type MaybeUser = firebase.User | null | undefined;

const authContext = createContext<AuthContext>({} as AuthContext);

// Provider component that wraps your app and makes auth object
// available to any child component that calls useAuth().
export function AuthProvider({ children }: any) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState<MaybeUser>(undefined);

  // Wrap any Firebase methods we want to use making sure to save the user to state.
  const signIn = async (email: string, password: string): Promise<firebase.User | null> => {
    const response = await firebase.auth().signInWithEmailAndPassword(email, password);
    setUser(response.user);
    return response.user;
  };

  const signUp = async (email: string, password: string): Promise<firebase.User | null> => {
    const response = await firebase.auth().createUserWithEmailAndPassword(email, password);
    setUser(response.user);
    return response.user;
  };

  const signOut = async (): Promise<void> => {
    await firebase.auth().signOut();
    setUser(null);
  };

  const sendPasswordResetEmail = async (email: string): Promise<boolean> => {
    await firebase.auth().sendPasswordResetEmail(email);
    return true;
  };

  const confirmPasswordReset = async (code: string, password: string): Promise<boolean> => {
    await firebase.auth().confirmPasswordReset(code, password);
    return true;
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any component
  // that utilizes this hook to re-render with the latest auth object.
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // Return the user object and auth methods
  return {
    user,
    signIn,
    signUp,
    signOut,
    sendPasswordResetEmail,
    confirmPasswordReset,
  };
}
