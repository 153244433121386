/*
 * Simple React hook that return a boolean;
 *   - True at the mount time
 *   - Then always false
 *
 * See also:
 *   - useEffectOnce(): A modified useEffect() executed only on mount
 *   - useUpdateEffect(): A modified useEffect() executed only on updates (skip first render)
 *   - useIsMounted(): Callback function to avoid Promise execution after component un-mount
 */

import { useRef } from 'react';

export function useIsFirstRender(): boolean {
  const isFirst = useRef(true);

  if (isFirst.current) {
    isFirst.current = false;

    return true;
  }

  return isFirst.current;
}
