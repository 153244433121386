import { makeStyles } from '@mui/styles';

export default makeStyles({
  instruction: {},
  picture: {},
  image: {
    width: '100%',
    height: 'auto',
    borderRadius: '6px',
    display: 'block',
    maxWidth: '100%',
  },
});
