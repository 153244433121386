// components
import { PlaceHolder, RouteDescription } from 'components';

// prefixes
const USER_PATH = 'user';

export const userRouteDescriptions: RouteDescription = {
  type: 'group',
  collapsed: false,
  fullName: 'user-name-placeholder',
  views: [
    {
      type: 'node',
      path: `${USER_PATH}/profile`,
      fullName: 'My Profile',
      miniName: '-',
      fullNameRtl: 'ملفي',
      miniNameRtl: 'مع',
      component: () => (
        <PlaceHolder title="My Profile" description="Allow the user to view their profile." />
      ),
    },
    {
      type: 'node',
      path: `${USER_PATH}/edit-profile`,
      fullName: 'Edit Profile',
      miniName: '-',
      fullNameRtl: 'تعديل الملف الشخصي',
      miniNameRtl: 'هوع',
      component: () => (
        <PlaceHolder title="Edit Profile" description="Allow the user to edit their profile." />
      ),
    },
    {
      type: 'node',
      path: `${USER_PATH}/settings`,
      fullName: 'Settings',
      miniName: '-',
      fullNameRtl: 'إعدادات',
      miniNameRtl: 'و',
      component: () => (
        <PlaceHolder title="Settings" description="Allow the user to edit their settings." />
      ),
    },
  ],
};
