import type { PayloadAction } from '@reduxjs/toolkit';

import { ErrorPayload, IUser, UserActionType, SignInSuccessPayload } from './user.types';

interface UserState {
  currentUser: IUser | null;
  error: string | null;
}

const INITIAL_STATE: UserState = {
  currentUser: null,
  error: null,
};

export const userReducer = (
  state: UserState = INITIAL_STATE,
  action: PayloadAction<any, UserActionType>,
): UserState => {
  switch (action.type) {
    case UserActionType.SIGN_IN_SUCCESS:
      const { user }: SignInSuccessPayload = action.payload;
      return {
        ...state,
        currentUser: user,
        error: null,
      };
    case UserActionType.SIGN_OUT_SUCCESS:
      return {
        ...state,
        currentUser: null,
        error: null,
      };
    case UserActionType.SIGN_IN_FAILURE:
    case UserActionType.SIGN_OUT_FAILURE:
    case UserActionType.SIGN_UP_FAILURE:
      const { error }: ErrorPayload = action.payload;
      return {
        ...state,
        error,
      };
    default:
      return state;
  }
};
