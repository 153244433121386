import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { boxShadow } from 'components/styles/common.styles';

import { list, whiteAfter } from '../common.styles';

interface UserLinksStylesProps {
  rtlMode: boolean;
  backgroundColor: string;
}

export default makeStyles<Theme, UserLinksStylesProps>((theme: Theme) => ({
  list,
  whiteAfter,
  user: ({ backgroundColor }) => ({
    ...(backgroundColor === 'white' ? whiteAfter : {}),
    paddingBottom: '10px',
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '0',
      right: '15px',
      height: '1px',
      width: 'calc(100% - 30px)',
      backgroundColor: 'hsla(0,0%,100%,.3)',
    },
  }),
  photo: {
    transition: 'all 300ms linear',
    width: '34px',
    height: '34px',
    overflow: 'hidden',
    zIndex: 5,
    borderRadius: '50%',
    position: 'absolute',
    top: '15px',
    left: '1px',
    marginRight: ({ rtlMode }) => (rtlMode ? '24px' : '11px'),
    marginLeft: ({ rtlMode }) => (rtlMode ? '12px' : '23px'),
    ...boxShadow,
  },
  avatarImg: {
    width: '100%',
    border: '0',
  },
}));
