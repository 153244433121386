import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  drawerWidth,
  drawerMiniWidth,
  transition,
  containerFluid,
} from 'components/styles/common.styles';

export default makeStyles((theme: Theme) => ({
  wrapper: {
    minHeight: 'calc(100% + env(safe-area-inset-top))',
    position: 'relative',
    top: '0',
    '&:after': {
      display: 'table',
      clear: 'both',
      content: '" "',
    },
  },
  mainPanel: {
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    position: 'relative',
    float: 'right',
    ...transition,
    width: '100%',
    overflowScrolling: 'touch',
    maxHeight: '100%',
    overflow: 'auto',
  },
  content: {
    marginTop: '70px',
  },
  container: {
    ...containerFluid,
  },
  map: {
    width: '100%',
  },
  mainPanelSidebarMini: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerMiniWidth}px)`,
    },
  },
  mainPanelWithPerfectScrollbar: {
    overflow: 'hidden !important',
  },
}));
