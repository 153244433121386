import React from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { firebaseAuth } from 'firecore/firebase.core';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import * as userActionCreators from 'redux/user/user.actions';
import { LoginPage } from './Login.component';

export function LoginPageContainer() {
  const [signInWithEmailAndPassword, user, error] = useSignInWithEmailAndPassword(firebaseAuth);
  console.log({ user, error });

  const onSignInWithEmailAndPassword = (args: { email: string; password: string }) => {
    signInWithEmailAndPassword(args.email, args.password);
  };
  const signInWithGoogle = () => alert('Signing in with Google...');

  return (
    <LoginPage
      currentUser={user}
      authenticationError={error}
      onSignInWithEmailAndPassword={onSignInWithEmailAndPassword}
      onSignInWithGoogle={signInWithGoogle}
      onSignOut={() => {}}
    />
  );
}

export function LoginPageHookContainer() {
  const user = useAppSelector((state) => state.user.currentUser);
  const error = useAppSelector((state) => state.user.error);

  const dispatch = useAppDispatch();
  const userActions = bindActionCreators(userActionCreators, dispatch);

  return (
    <LoginPage
      currentUser={user}
      authenticationError={Boolean(error)}
      onSignInWithEmailAndPassword={userActions.emailSignInStart}
      onSignInWithGoogle={userActions.googleSignInStart}
      onSignOut={userActions.signOutStart}
    />
  );
}
