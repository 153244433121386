import React, { useState, useEffect } from 'react';

export type LoadingIndicatorProps = {
  isLoading: boolean;
  children: any;
};

export function LoadingIndicator({
  isLoading,
  children,
}: LoadingIndicatorProps): JSX.Element | null {
  const [isPastDelay, setIsPastDelay] = useState(false);

  useEffect(() => {
    const delayTimer = setTimeout(() => setIsPastDelay(true), 200);
    return () => clearTimeout(delayTimer);
  });

  if (isLoading) {
    if (!isPastDelay) {
      return null;
    }

    return <div>loading...</div>;
  }

  return children;
}
