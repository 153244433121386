import React, { useState, useRef } from 'react';
import cx from 'classnames';

import {
  Box,
  ClickAwayListener,
  Divider,
  Grow,
  MenuList,
  MenuItem,
  Paper,
  Popper,
} from '@mui/material';

import { Button, CustomInput } from 'components';
import useStyles from 'components/Navbars/AdminNavbarLinks.styles';

// icons
import PersonIcon from '@mui/icons-material/Person';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SearchIcon from '@mui/icons-material/Search';

export type AdminNavbarLinksProps = {
  rtlMode?: boolean;
  signOutStart?: any;
};

export function AdminNavbarLinks({ rtlMode, signOutStart }: AdminNavbarLinksProps) {
  const classes = useStyles();
  const [openNotification, setOpenNotification] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const anchorNotification = useRef(null);
  const anchorProfile = useRef(null);

  const handleClickNotification = () => {
    setOpenNotification(!openNotification);
  };

  const handleCloseNotification = () => {
    setOpenNotification(false);
  };

  const handleClickProfile = () => {
    setOpenProfile(!openProfile);
  };

  const handleCloseProfile = () => {
    setOpenProfile(false);
  };

  const searchButton = cx(classes.top, classes.searchButton, {
    [classes.searchRTL]: rtlMode,
  });

  const dropdownItem = cx(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlMode,
  });

  const wrapper = cx({ [classes.wrapperRTL]: rtlMode });
  const managerClasses = cx({ [classes.managerClasses]: true });

  return (
    <div className={wrapper}>
      {/* ! Search bar */}
      <span>
        <CustomInput
          rtlMode={rtlMode}
          formControlProps={{
            className: classes.top + ' ' + classes.search,
          }}
          inputProps={{
            placeholder: rtlMode ? 'بحث' : 'Search',
            inputProps: {
              'aria-label': rtlMode ? 'بحث' : 'Search',
              className: classes.searchInput,
            },
          }}
        />
        <Button color="white" aria-label="edit" justIcon round className={searchButton}>
          <SearchIcon className={classes.headerLinksSvg + ' ' + classes.searchIcon} />
        </Button>
      </span>

      {/* ! Notification menu */}
      <div className={managerClasses}>
        <Button
          color="transparent"
          justIcon
          aria-label="Notifications"
          aria-owns={openNotification ? 'notification-menu-list' : undefined}
          aria-haspopup="true"
          onClick={handleClickNotification}
          className={rtlMode ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            root: rtlMode ? classes.labelRTL : '',
          }}
          ref={anchorNotification}
        >
          <NotificationsIcon
            className={cx(classes.headerLinksSvg, classes.links, { [classes.linksRTL]: rtlMode })}
          />
          <span className={classes.notifications}>5</span>
          <Box sx={{ display: { xs: 'block ', md: 'none' } }}>
            <span onClick={handleClickNotification} className={classes.linkText}>
              {rtlMode ? 'إعلام' : 'Notification'}
            </span>
          </Box>
        </Button>
        <Popper
          open={openNotification}
          anchorEl={anchorNotification.current}
          transition
          disablePortal
          placement="bottom"
          className={cx({
            [classes.popperClose]: !openNotification,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: '0 0 0' }}>
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList role="menu">
                    <MenuItem onClick={handleCloseNotification} className={dropdownItem}>
                      {rtlMode
                        ? 'إجلاء أوزار الأسيوي حين بل, كما'
                        : 'Mike John responded to your email'}
                    </MenuItem>
                    <MenuItem onClick={handleCloseNotification} className={dropdownItem}>
                      {rtlMode ? 'شعار إعلان الأرضية قد ذلك' : 'You have 5 new tasks'}
                    </MenuItem>
                    <MenuItem onClick={handleCloseNotification} className={dropdownItem}>
                      {rtlMode ? 'ثمّة الخاصّة و على. مع جيما' : "You're now friend with Andrew"}
                    </MenuItem>
                    <MenuItem onClick={handleCloseNotification} className={dropdownItem}>
                      {rtlMode ? 'قد علاقة' : 'Another Notification'}
                    </MenuItem>
                    <MenuItem onClick={handleCloseNotification} className={dropdownItem}>
                      {rtlMode ? 'قد فاتّبع' : 'Another One'}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>

      {/* ! User menu */}
      <div className={managerClasses}>
        <Button
          color="transparent"
          aria-label="Person"
          justIcon
          aria-owns={openNotification ? 'profile-menu-list' : undefined}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={rtlMode ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{ root: cx({ [classes.labelRTL]: rtlMode }) }}
          ref={anchorProfile}
        >
          <PersonIcon
            className={cx(classes.headerLinksSvg, classes.links, { [classes.linksRTL]: rtlMode })}
          />
          <Box sx={{ display: { xs: 'block ', md: 'none' } }}>
            <span onClick={handleClickProfile} className={classes.linkText}>
              {rtlMode ? 'الملف الشخصي' : 'Profile'}
            </span>
          </Box>
        </Button>
        <Popper
          open={openProfile}
          anchorEl={anchorProfile.current}
          transition
          disablePortal
          placement="bottom"
          className={cx({
            [classes.popperClose]: !openProfile,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: '0 0 0' }}>
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem onClick={handleCloseProfile} className={dropdownItem}>
                      {rtlMode ? 'الملف الشخصي' : 'Profile'}
                    </MenuItem>
                    <MenuItem onClick={handleCloseProfile} className={dropdownItem}>
                      {rtlMode ? 'الإعدادات' : 'Settings'}
                    </MenuItem>
                    <Divider light />
                    <MenuItem onClick={() => signOutStart()} className={dropdownItem}>
                      {rtlMode ? 'الخروج' : 'Log out'}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
