import React, { ReactElement } from 'react';
import { List } from '@mui/material';
import avatar from 'assets/img/faces/dj-angel.jpg';
import { ForegroundColors } from 'components/styles/common.types';
import { LinkGroup } from '../index';
import useStyles from './UserLinks.styles';
import { userRouteDescriptions } from 'routes';

export interface UserLinksProps {
  userName: string;
  color: ForegroundColors;
  backgroundColor: string;
  rtlMode: boolean;
  miniMode: boolean;
}

export function UserLinks({
  userName,
  color,
  backgroundColor,
  rtlMode,
  miniMode,
}: UserLinksProps): ReactElement {
  const classes = useStyles({ rtlMode, backgroundColor });
  const userRoutes = { ...userRouteDescriptions, fullName: userName };

  return (
    <div className={classes.user}>
      <div className={classes.photo}>
        <img src={avatar} className={classes.avatarImg} alt="..." />
      </div>
      <List className={classes.list}>
        <LinkGroup route={userRoutes} color={color} rtlMode={rtlMode} miniMode={miniMode} />
      </List>
    </div>
  );
}
