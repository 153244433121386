import { makeStyles } from '@mui/styles';

export default makeStyles({
  container: {
    backgroundColor: '#1a1a1a',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  text: {
    color: '#fff',
    marginTop: '1rem',
    fontSize: '1.5rem',
  },
  spinner: {
    marginTop: '1rem',
  },
});
