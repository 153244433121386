import {
  RouteDescriptions,
  RouteDescription,
  isRouteGroup,
  isRouteNode,
} from './partials/common.types';

// This creates the initial state of this component based on the collapse routes
// that it gets through this.props.routes
export const getIsCollapsedStates = (routes: RouteDescriptions) => {
  let initialState = {};
  routes.map((route: RouteDescription) => {
    if (isRouteGroup(route)) {
      initialState = {
        // [route.isCollapsedState as any]: getInitialIsCollapsedState(route.views),
        ...getIsCollapsedStates(route.views),
        ...initialState,
      };
    }
    return null;
  });
  return initialState;
};

// This verifies if any of the collapses should be default opened on a re-render of this component
// for example, on the refresh of the page,
// while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
export const getInitialIsCollapsedState = (routes: RouteDescriptions): boolean => {
  for (let i = 0; i < routes.length; i++) {
    const route = routes[i];
    console.log(route.fullName);
    if (isRouteGroup(route) && getInitialIsCollapsedState(route.views)) {
      return true;
    } else if (isRouteNode(route) && window.location.href.indexOf(route.path) !== -1) {
      return true;
    }
  }
  return false;
};

// Verifies if routeName is the one active (in browser input)
export const isActiveRoute = (routeName: string): boolean => {
  // return window.location.href.indexOf(routeName) > -1 ? 'active' : '';
  return window.location.href.indexOf(routeName) > -1 ? true : false;
};

export const validateRoutes = (routes: RouteDescriptions): void => {
  throw new Error('Todo: Routes need to validated');
};
