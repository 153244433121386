import React from 'react';
import axios from 'axios';
import StripeCheckout from 'react-stripe-checkout';

import useStyles from './StripeCheckoutButton.styles';

export type StripeCheckoutButtonProps = {
  price: number;
};

export function StripeCheckoutButton({ price }: StripeCheckoutButtonProps): JSX.Element {
  const classes = useStyles();

  const priceForStripe = price * 100;
  const publishableKey = 'pk_test_7ABOnnrEtkEGLSjegLTXJJbk00fzwECPet';
  const baseUrl = 'https://us-central1-sunny-196f4.cloudfunctions.net/api';

  const onToken = (token: any) => {
    axios({
      url: baseUrl + '/stripe/payment',
      method: 'post',
      data: {
        amount: priceForStripe,
        token,
      },
    })
      .then((response) => {
        alert('Payment Successful!');
      })
      .catch((error) => {
        console.log('Payment error:', JSON.parse(error));
        alert(
          'There was an issue with your payment. Please make sure you use the provided credit card.',
        );
      });
  };

  return (
    <div className={classes.container}>
      <div>
        <StripeCheckout
          label="Pay Now"
          name="Sunny"
          billingAddress
          shippingAddress
          image=""
          description={`Your total is $${price}`}
          amount={priceForStripe}
          panelLabel="Pay Now"
          token={onToken}
          stripeKey={publishableKey}
        />
      </div>
      <div>
        <p>*Please use the following test credit card for payments*</p>
        <p>Num: 4242 4242 4242 4242 -- Exp: 01/23 -- CVV: 123</p>
      </div>
    </div>
  );
}

export default StripeCheckoutButton;
