import React, { useMemo } from 'react';
import cx from 'classnames';

import { List } from '@mui/material';
// import { AdminNavbarLinks } from '../Navbars/AdminNavbarLinks.component';
import { BackgroundColors, ForegroundColors } from 'components/styles/common.types';
import { Header, Footer, LinkGroup, UserLinks, Wrapper, RouteDescription } from '../index';
import useStyles from './Content.styles';

export interface ContentProps {
  routes: RouteDescription[];
  open: boolean;
  miniMode: boolean;
  rtlMode: boolean;
  userName: string;
  logoText: string;
  logoImage: string;
  sidebarImage: string;
  highlightColor?: ForegroundColors;
  backgroundColor?: BackgroundColors;
  onDrawerToggle: () => void;
  onSidebarToggle: () => void;
  onLogout: () => void;
  onMouseOverHeader: (event: any) => void;
  onMouseOutHeader: (event: any) => void;
  showNavbarLink: boolean;
}

export const Content = ({
  routes,
  open,
  miniMode,
  rtlMode,
  userName,
  logoText,
  logoImage,
  sidebarImage,
  highlightColor = 'blue',
  backgroundColor = 'black',
  onDrawerToggle,
  onSidebarToggle,
  onLogout,
  onMouseOverHeader,
  onMouseOutHeader,
  showNavbarLink,
}: ContentProps) => {
  const classes = useStyles();

  const wrapperClass = cx(classes.wrapper, {
    [classes.wrapperPaperMini]: miniMode,
    [classes.wrapperWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1,
  });

  const userLinksComponent = useMemo(
    () => (
      <UserLinks
        userName={userName}
        backgroundColor={backgroundColor}
        color={highlightColor}
        rtlMode={rtlMode}
        miniMode={miniMode}
      />
    ),
    [userName, miniMode, rtlMode, backgroundColor, highlightColor],
  );

  const appLinksComponent = useMemo(
    () => (
      <List className={classes.list}>
        {routes.map((route: RouteDescription) => (
          <LinkGroup
            key={route.fullName}
            route={route}
            color={highlightColor}
            rtlMode={rtlMode}
            miniMode={miniMode}
          />
        ))}
      </List>
    ),
    [routes, miniMode, rtlMode, highlightColor, classes.list],
  );

  return (
    <>
      {!showNavbarLink && (
        <Header
          logoText={logoText}
          logoImage={logoImage}
          backgroundColor={backgroundColor!}
          rtlMode={rtlMode}
          miniMode={miniMode}
          toggleButtonIcon={miniMode ? 'open' : 'close'}
          onSidebarToggle={onSidebarToggle}
          onMouseOver={onMouseOverHeader}
          onMouseOut={onMouseOutHeader}
        />
      )}
      <Wrapper
        className={wrapperClass}
        // headerComponent={showNavbarLink ? <AdminNavbarLinks rtlMode={rtlMode} /> : null}
        headerComponent={null}
        userLinksComponent={userLinksComponent}
        appLinksComponent={appLinksComponent}
      />
      <Footer rtlMode={rtlMode} miniMode={miniMode} onLogout={onLogout} />
      {sidebarImage !== undefined ? (
        <div className={classes.background} style={{ backgroundImage: `url(${sidebarImage})` }} />
      ) : null}
    </>
  );
};
