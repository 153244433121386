import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { userReducer } from './user/user.reducer';

const persistConfig = {
  key: 'root',
  storage,
};

const combinedReducers = combineReducers({
  user: userReducer,
});

export const rootReducer = persistReducer(persistConfig, combinedReducers);
