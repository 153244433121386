import React from 'react';
import { ChevronLeftRounded, ChevronRightRounded } from '@mui/icons-material';
import { BackgroundColors } from 'components';
import useStyles from './Header.styles';

export interface HeaderProps {
  logoText: string;
  logoImage: string;
  backgroundColor: BackgroundColors;
  rtlMode: boolean;
  miniMode: boolean;
  toggleButtonIcon: 'open' | 'close';
  onSidebarToggle: () => void;
  onMouseOver?: React.MouseEventHandler<HTMLDivElement> | undefined;
  onMouseOut?: React.MouseEventHandler<HTMLDivElement> | undefined;
}

export const Header = React.memo(
  ({
    logoText,
    logoImage,
    backgroundColor = 'blue',
    rtlMode,
    miniMode,
    toggleButtonIcon,
    onSidebarToggle,
    onMouseOver,
    onMouseOut,
  }: HeaderProps): JSX.Element => {
    const classes = useStyles({ miniMode, rtlMode, backgroundColor });

    return (
      <div className={classes.logo} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
        <div className={classes.logoContainer}>
          <div className={classes.spacer}>
            <a href="#home" target="_blank" className={classes.logoMini}>
              <img src={logoImage} alt="logo" className={classes.img} />
            </a>
            <a href="#home" target="_blank" className={classes.logoNormal}>
              {logoText}
            </a>
          </div>
          <div className={classes.toggleContainer}>
            <div className={classes.toggleButton} onClick={onSidebarToggle}>
              {toggleButtonIcon === 'open' ? <ChevronRightRounded /> : <ChevronLeftRounded />}
            </div>
          </div>
        </div>
      </div>
    );
  },
);
