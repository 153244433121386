import React from 'react';
import { AppBar, Box, Toolbar } from '@mui/material';

import { Button, NavbarColors } from 'components';
import { AdminNavbarLinks } from './AdminNavbarLinks.component';
import useStyles from './AdminNavbar.styles';

// Ions
import MenuIcon from '@mui/icons-material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ViewListIcon from '@mui/icons-material/ViewList';

export type AdminNavbarProps = {
  foregroundColor?: NavbarColors;
  brandText?: string;
  rtlMode?: boolean;
  miniMode?: boolean;
  onDrawerToggle?: () => void;
  onSidebarMinimize?: () => void;
};

export function AdminNavbar({
  foregroundColor = 'transparent',
  miniMode,
  rtlMode,
  brandText,
  onDrawerToggle,
  onSidebarMinimize,
}: AdminNavbarProps) {
  const classes = useStyles({ color: foregroundColor, rtlMode: Boolean(rtlMode) });

  return (
    <AppBar className={classes.appBar}>
      <Toolbar className={classes.container}>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <div className={classes.sidebarMinimize}>
            <Button justIcon round color="white" onClick={onSidebarMinimize}>
              {miniMode ? (
                <ViewListIcon className={classes.sidebarMiniIcon} />
              ) : (
                <MoreVertIcon className={classes.sidebarMiniIcon} />
              )}
            </Button>
          </div>
        </Box>
        <div className={classes.flex}>
          <Button href="#" className={classes.title} color="transparent">
            {brandText}
          </Button>
        </div>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <AdminNavbarLinks rtlMode={rtlMode} />
        </Box>
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={onDrawerToggle}
          >
            <MenuIcon />
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
