import React, { useEffect, useRef } from 'react';

// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';

let perfectScrollbar: PerfectScrollbar | null = null;

export interface WrapperProps {
  className: string;
  headerComponent: any;
  userLinksComponent: any;
  appLinksComponent: any;
}

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
export const Wrapper = React.memo(
  ({
    className,
    headerComponent,
    userLinksComponent,
    appLinksComponent,
  }: WrapperProps): JSX.Element => {
    const wrapperRef = useRef<any>(null);

    useEffect(() => {
      // let platform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown';
      if (navigator.platform.indexOf('Win') > -1) {
        perfectScrollbar = new PerfectScrollbar(wrapperRef.current, {
          suppressScrollX: true,
          suppressScrollY: false,
        });
      }

      return function cleanup() {
        // if (navigator.platform.indexOf('Win') > -1) {
        if (perfectScrollbar) {
          perfectScrollbar.destroy();
        }
      };
    });

    return (
      <div className={className} ref={wrapperRef}>
        {headerComponent}
        {userLinksComponent}
        {appLinksComponent}
      </div>
    );
  },
);
