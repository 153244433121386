import React from 'react';
import { makeStyles } from '@mui/styles';
import Grid, { GridProps } from '@mui/material/Grid';

const useStyle = makeStyles({
  grid: {
    padding: '0 15px !important',
  },
});

export interface GridItemProps extends GridProps {
  className?: string;
}

export function GridItem({ children, className, ...rest }: GridItemProps) {
  const classes = useStyle();
  return (
    <Grid item {...rest} className={classes.grid + ' ' + className}>
      {children}
    </Grid>
  );
}
