import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    padding: '0px 0px 10px 0px',
    margin: '0',
    display: 'block',
    position: 'relative',
    zIndex: 4,
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '0',
      height: '1px',
      right: '15px',
      width: 'calc(100% - 30px)',
      backgroundColor: 'hsla(0,0%,100%,.3)',
    },
  },
}));
