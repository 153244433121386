import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { container, cardTitle, whiteColor, grayColor } from 'components/styles/common.styles';

export default makeStyles((theme: Theme) => ({
  container: {
    ...container,
    zIndex: 4,
    [theme.breakpoints.down('md')]: {
      paddingBottom: '100px',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardTitle: {
    ...cardTitle,
    color: whiteColor,
  },

  customButtonClass: {
    '&,&:focus,&:hover': {
      color: whiteColor,
    },
    marginLeft: '5px',
    marginRight: '5px',
  },
  inputAdornmentIcon: {
    color: grayColor[6],
  },
  cardHidden: {
    opacity: '0',
    transform: 'translate3d(0, -60px, 0)',
  },
  cardHeader: {
    marginBottom: '20px',
    textAlign: 'center',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  googleButton: {
    margin: '20px 5px',
    backgroundColor: '#4285f4',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#357ae8',
    },
  },
  noErrorSpacer: {
    height: '21px',
    marginBottom: '10px',
  },
  errorMessage: {
    color: 'red',
  },
  divider: {
    margin: '0px 5px 8px',
  },
  cardFooter: {
    justifyContent: 'center !important',
  },
}));
