import { styled } from '@mui/material/styles';

export interface SpinnerOverlayProps {
  backgroundColor?: string;
}

export const SpinnerOverlay = styled('div')<SpinnerOverlayProps>(({ backgroundColor }) => ({
  /* centre by subtracting twice height of app header */
  // height: 'calc(100vh - 140px)',
  height: '100vh',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: backgroundColor || 'transparent',
}));

export const SpinnerContainer = styled('div')`
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(195, 195, 195, 0.6);
  border-radius: 50%;
  border-top-color: #636767;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
`;
