import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { defaultFont, grayColor, hexToRgb } from 'components/styles/common.styles';
import { whiteAfter } from '../common.styles';

export interface HeaderStyleProps {
  miniMode: boolean;
  rtlMode: boolean;
  backgroundColor: string;
}

export default makeStyles<Theme, HeaderStyleProps>((theme: Theme) => ({
  whiteAfter,
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  img: {
    width: '35px',
    border: '0',
  },
  logo: ({ backgroundColor }) => ({
    ...(backgroundColor === 'white' ? whiteAfter : {}),
    padding: '15px 0px',
    margin: '0',
    display: 'block',
    position: 'relative',
    zIndex: 4,
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '0',
      height: '1px',
      right: '15px',
      width: 'calc(100% - 30px)',
      backgroundColor: 'hsla(0,0%,100%,.3)',
    },
  }),
  toggleContainer: ({ miniMode }) => ({
    display: 'flex',
    width: '50px',
    zIndex: 6,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    marginRight: miniMode ? '15px' : '5px',
  }),
  toggleButton: {
    transition: 'all 300ms linear',
    border: '1px solid hsla(0,0%,100%,.3)',
    borderRadius: '3px',
    padding: '6.5px 12px',
    margin: '5px 1px',
    width: '30px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(' + hexToRgb(grayColor[17]) + ', 0.2)',
    },
  },
  spacer: ({ miniMode }) => ({
    transition: 'all 300ms linear',
    display: 'flex',
    flexGrow: 1,
    width: miniMode ? '0' : '1',
    opacity: miniMode ? '0' : '100%',
  }),
  logoMini: ({ rtlMode }) => ({
    transition: 'all 300ms linear',
    opacity: 1,
    textAlign: 'center',
    width: '35px',
    display: 'inline-block',
    maxHeight: '35px',
    marginLeft: rtlMode ? '10px' : '24px',
    marginRight: rtlMode ? '24px' : '10px',
    marginTop: '2px',
    color: 'inherit',
  }),
  logoNormal: ({ miniMode, rtlMode }) => ({
    ...defaultFont,
    transition: 'all 300ms linear',
    opacity: miniMode ? '0' : '1',
    transform: `translate3d(${miniMode ? (rtlMode ? '25' : '-25') : '0'}px, 0, 0)`,
    textTransform: 'uppercase',
    padding: '5px 0px',
    fontSize: '18px',
    whiteSpace: 'nowrap',
    fontWeight: 400,
    lineHeight: '30px',
    textAlign: rtlMode ? 'right' : 'left',
    overflow: 'hidden',
    '&,&:hover,&:focus': {
      color: 'inherit',
    },
  }),
}));
