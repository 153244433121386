import { takeLatest, put, all, call } from 'typed-redux-saga';
// import { takeLatest, put, all, call } from 'redux-saga/effects';

import 'firebase/compat/firestore';
import 'firebase/compat/auth';

import {
  SignUpPayloadWrapper,
  SignWithEmailPayloadWrapper,
  SignInAfterSignUpPayloadWrapper,
  UserActionType,
} from './user.types';

import {
  signInSuccess,
  signInFailure,
  signOutSuccess,
  signOutFailure,
  signUpSuccess,
  signUpFailure,
} from './user.actions';

import {
  googleProvider,
  createOrRetrieveUserProfileDocument,
  getCurrentUser,
  IUserDetails,
} from 'firecore/utils/firebase.utils';

import { firebaseAuth, UserCredential } from 'firecore/firebase.core';
import { IUser } from './user.types';

// ======================================================
// Controller saga
// ======================================================

export function* userSagas() {
  yield* all([
    call(onGoogleSignInStart),
    call(onEmailSignInStart),
    call(onReauthenticateCurrentUserStart),
    call(onSignOutStart),
    call(onSignUpStart),
    call(onSignUpSuccess),
  ]);
}

// ======================================================
// Higher order sagas
// ======================================================

function* onGoogleSignInStart() {
  yield* takeLatest(UserActionType.GOOGLE_SIGN_IN_START, signInWithGoogle);
}

function* onEmailSignInStart() {
  yield* takeLatest(UserActionType.EMAIL_SIGN_IN_START as any, signInWithEmail);
}

function* onReauthenticateCurrentUserStart() {
  yield* takeLatest(UserActionType.REAUTHENTICATE_CURRENT_USER, reauthenticateCurrentUser);
}

function* onSignOutStart() {
  yield* takeLatest(UserActionType.SIGN_OUT_START, signOut);
}

function* onSignUpStart() {
  yield* takeLatest(UserActionType.SIGN_UP_START as any, signUp);
}

function* onSignUpSuccess() {
  yield* takeLatest(UserActionType.SIGN_UP_SUCCESS as any, signInAfterSignUp);
}

// ======================================================
// Lower order sagas
// ======================================================

function* getUserDataFromUserAuth(userDetails: IUserDetails, additionalData?: any) {
  const userRef = yield* call(createOrRetrieveUserProfileDocument, userDetails, additionalData);
  if (!userRef) throw new Error('Failed to retrieve user');
  const userSnapshot: any = yield* call(userRef.get);
  const userData: IUser = { id: userSnapshot.id, ...userSnapshot.data() };
  return userData;
}

function* signInWithGoogle() {
  try {
    const { user }: any = yield* call([firebaseAuth, firebaseAuth.signInWithPopup], googleProvider);
    if (!user) throw new Error('Failed to retrieve user');
    const userData = yield* call(getUserDataFromUserAuth, user);
    yield* put(signInSuccess({ user: userData }));
  } catch (error) {
    yield* put(signInFailure({ error }));
  }
}

export function* signInWithEmail({ payload: { email, password } }: SignWithEmailPayloadWrapper) {
  try {
    const { user }: any = yield* call(
      [firebaseAuth, firebaseAuth.signInWithEmailAndPassword],
      email,
      password,
    );
    if (!user) throw new Error('Failed to retrieve user');
    const userData = yield* call(getUserDataFromUserAuth, user);
    yield* put(signInSuccess({ user: userData }));
  } catch (error) {
    yield* put(signInFailure({ error }));
  }
}

function* reauthenticateCurrentUser() {
  try {
    const userAuth: any = yield* call(getCurrentUser);
    if (!userAuth) throw new Error('Failed to retrieve user');
    const userData = yield* call(getUserDataFromUserAuth, userAuth);
    yield* put(signInSuccess({ user: userData }));
  } catch (error) {
    yield* put(signInFailure({ error }));
  }
}

function* signOut() {
  try {
    yield* call([firebaseAuth, firebaseAuth.signOut]);
    yield* put(signOutSuccess());
  } catch (error) {
    yield* put(signOutFailure({ error }));
  }
}

function* signUp({ payload: { email, password, displayName } }: SignUpPayloadWrapper) {
  try {
    const { user }: UserCredential = yield* call(
      [firebaseAuth, firebaseAuth.createUserWithEmailAndPassword],
      email,
      password,
    );
    if (!user) throw new Error('Failed to retrieve user');
    yield* put(signUpSuccess({ uid: user.uid, displayName, email }));
  } catch (error) {
    yield* put(signUpFailure({ error }));
  }
}

function* signInAfterSignUp({
  payload: { uid, email, displayName },
}: SignInAfterSignUpPayloadWrapper) {
  const userData = yield* call(getUserDataFromUserAuth, { uid, email, displayName });
  yield* put(signInSuccess({ user: userData }));
}
