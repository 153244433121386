import { all, call } from 'redux-saga/effects';

import { regeneratingSaga } from './saga.utils';
import { userSagas } from './user/user.sagas';

export const regeneratingRootSaga = regeneratingSaga([userSagas]);

export function* rootSaga() {
  yield all([call(userSagas)]);
}
