import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { whiteColor, blackColor, hexToRgb } from 'components/styles/common.styles';

export default makeStyles((theme: Theme) => ({
  wrapper: {
    height: 'auto',
    minHeight: '100vh',
    position: 'relative',
    top: '0',
  },
  fullPage: {
    position: 'relative',
    minHeight: '100vh',
    display: 'flex',
    padding: '16px 0',
    margin: '0',
    border: '0',
    color: whiteColor,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    [theme.breakpoints.down('md')]: {
      // minHeight: 'fit-content!important',
    },
    '& footer': {
      position: 'absolute',
      bottom: '0',
      width: '100%',
      border: 'none !important',
    },
    '&:before': {
      backgroundColor: 'rgba(' + hexToRgb(blackColor) + ', 0.65)',
    },
    '&:before,&:after': {
      display: 'block',
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: '0',
      left: '0',
      zIndex: '2',
    },
  },
}));
