import { AllColors } from 'components';

// Icons
import CloudIcon from '@mui/icons-material/Cloud';
import GroupIcon from '@mui/icons-material/Group';
import PollIcon from '@mui/icons-material/Poll';

export type ProductFeatureDescription = {
  title: string;
  description: string;
  icon: any;
  color: AllColors;
};

export const productFeatureDescriptions: ProductFeatureDescription[] = [
  {
    title: 'Quantified-self',
    description:
      'Become a better you by quantifying all aspect of your behaviour and health statistics.',
    icon: PollIcon,
    color: 'primary',
    // color: 'warning',
  },
  {
    title: 'Automatic collection',
    description:
      'Connect to multiple Internet-of-Things (IoT) devices for effortless data collection.',
    icon: CloudIcon,
    color: 'primary',
    // color: 'info',
  },
  {
    title: 'Insightful nudges',
    // description:'Bring your data to life with personalised nudges from you friendly A.I. cognitive assistant.',
    description:
      'Bring your data to life with personalised nudges from your family of A.I. cognitive assistants.',
    icon: GroupIcon,
    color: 'primary',
    // color: 'success',
  },
];
