import React from 'react';
import { ForegroundColors } from 'components';
import { RouteNodeDescription } from '../common.types';
import { LinkNode } from '../LinkNode';
import useStyles from './Footer.styles';
import LogoutIcon from '@mui/icons-material/Logout';

export interface FooterProps {
  color?: ForegroundColors;
  rtlMode: boolean;
  miniMode: boolean;
  onLogout: () => void;
}

const route: RouteNodeDescription = {
  type: 'node',
  path: '/auth/log-out',
  fullName: 'Logout',
  icon: LogoutIcon,
  component: () => <></>,
};

export const Footer = React.memo(({ color = 'blue', rtlMode, miniMode, onLogout }: FooterProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <LinkNode
        route={route}
        rtlMode={rtlMode}
        miniMode={miniMode}
        color={color}
        onClick={onLogout}
      />
      {/* <p>{miniMode ? '.' : 'Version: verify me'}</p> */}
    </div>
  );
});
