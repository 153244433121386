import React from 'react';
import classNames from 'classnames';

import useStyles from './CardFooter.styles';

export type CardFooterProps = {
  className?: string;
  children?: any;
  plain?: boolean;
  profile?: boolean;
  pricing?: boolean;
  testimonial?: boolean;
  stats?: boolean;
  chart?: boolean;
  product?: boolean;
};

export function CardFooter({
  className,
  children,
  plain,
  profile,
  pricing,
  testimonial,
  stats,
  chart,
  product,
  ...rest
}: CardFooterProps): JSX.Element {
  const classes = useStyles();

  const cardFooterClasses = classNames({
    [classes.cardFooter]: true,
    [classes.cardFooterPlain]: plain,
    [classes.cardFooterProfile]: profile || testimonial,
    [classes.cardFooterPricing]: pricing,
    [classes.cardFooterTestimonial]: testimonial,
    [classes.cardFooterStats]: stats,
    [classes.cardFooterChart]: chart || product,
    [className ? className : '']: className !== undefined,
  });
  return (
    <div className={cardFooterClasses} {...rest}>
      {children}
    </div>
  );
}
