import React from 'react';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';

import { AppBar, Box, Drawer, List, ListItem, ListItemText, Toolbar } from '@mui/material';

import { Button, NavbarColors } from 'components';
import { useToggle } from 'hooks';
import { isActiveRoute } from 'routes';
import useStyles from './AuthNavbar.styles';

// Icons
import MenuIcon from '@mui/icons-material/Menu';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
// import LockOpenIcon from '@mui/icons-material/LockOpen';
// import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

export type AuthNavbarProps = {
  color?: NavbarColors;
  brandText: string;
  brandUrl: string;
};

const listData = [
  { name: 'Register', path: '/auth/register', Icon: PersonAddIcon },
  { name: 'Login', path: '/auth/login', Icon: FingerprintIcon },
  // { name: 'Lock', path: '/auth/lock', Icon: LockOpenIcon },
  // { name: 'Pricing', path: '/auth/pricing', Icon: MonetizationOnIcon },
];

export function AuthNavbar({ color = 'transparent', brandText, brandUrl }: AuthNavbarProps) {
  const classes = useStyles({ color });
  const [open, toggleOpen] = useToggle(false);

  const renderLinks = () => (
    <List className={classes.list}>
      {listData.map(({ name, path, Icon }) => (
        <ListItem key={name} className={classes.listItem}>
          <NavLink
            to={path}
            className={cx(classes.navLink, {
              [classes.navLinkActive]: isActiveRoute(path),
            })}
            onClick={toggleOpen}
          >
            <Icon className={classes.listItemIcon} />
            <ListItemText
              primary={name}
              disableTypography={true}
              className={classes.listItemText}
            />
          </NavLink>
        </ListItem>
      ))}
    </List>
  );

  return (
    <AppBar position="absolute" className={classes.appBar}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          <Button href={brandUrl} className={classes.title} color="transparent">
            {brandText}
          </Button>
        </div>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>{renderLinks()}</Box>
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <Button
            className={classes.sidebarButton}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={toggleOpen}
          >
            <MenuIcon />
          </Button>
        </Box>
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <Drawer
            variant="temporary"
            anchor={'right'}
            open={open}
            classes={{ paper: classes.drawerPaper }}
            onClose={toggleOpen}
            // Better open performance on mobile.
            ModalProps={{ keepMounted: true }}
          >
            {renderLinks()}
          </Drawer>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
