import { makeStyles } from '@mui/styles';
import {
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  grayColor,
} from 'components/styles/common.styles';

export default makeStyles({
  cardText: {
    float: 'none',
    display: 'inline-block',
    marginRight: '0',
    borderRadius: '3px',
    backgroundColor: grayColor[0],
    padding: '15px',
    marginTop: '-20px',
  },
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
});
