import React from 'react';
import classNames from 'classnames';
import { MainColors } from '../styles/common.types';
import useStyles from './CardIcon.styles';

export type CardIconProps = {
  className?: string;
  children?: any;
  color: MainColors;
};

export function CardIcon({ className, children, color, ...rest }: CardIconProps) {
  const classes = useStyles();

  const cardIconClasses = classNames({
    [classes.cardIcon]: true,
    // [classes[`${color}CardHeader`]]: color,
    [className ? className : '']: className !== undefined,
  });

  return (
    <div className={cardIconClasses} {...rest}>
      {children}
    </div>
  );
}
