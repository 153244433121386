import { makeStyles } from '@mui/styles';
import {
  container,
  cardTitle,
  blackColor,
  hexToRgb,
  grayColor,
} from 'components/styles/common.styles';

import { customCheckboxRadioSwitch } from 'components/styles/customCheckboxRadioSwitch.styles';

export default makeStyles({
  ...customCheckboxRadioSwitch,
  cardTitle: {
    ...cardTitle,
    textAlign: 'center',
  },
  container: {
    ...container,
    position: 'relative',
    zIndex: 3,
    // paddingTop: "23vh"
  },
  cardSignUp: {
    borderRadius: '6px',
    boxShadow:
      '0 16px 24px 2px rgba(' +
      hexToRgb(blackColor) +
      ', 0.14), 0 6px 30px 5px rgba(' +
      hexToRgb(blackColor) +
      ', 0.12), 0 8px 10px -5px rgba(' +
      hexToRgb(blackColor) +
      ', 0.2)',
    marginBottom: '100px',
    padding: '40px 0px',
    marginTop: '15vh',
  },
  center: {
    textAlign: 'center',
  },
  right: {
    textAlign: 'right',
  },
  left: {
    textAlign: 'left',
  },
  form: {
    padding: '0 20px',
    position: 'relative',
  },
  socialTitle: {
    fontSize: '18px',
  },
  inputAdornment: {
    marginRight: '18px',
    position: 'relative',
  },
  inputAdornmentIcon: {
    color: grayColor[6],
  },
  customFormControlClasses: {
    margin: '0 12px',
  },
  checkboxLabelControl: {
    margin: '0',
  },
  checkboxLabel: {
    fontSize: '0.875rem',
    marginLeft: '6px',
    color: 'rgba(' + hexToRgb(blackColor) + ', 0.26)',
  },
});
