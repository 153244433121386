import { RouteDescription, RouteElement } from 'components';

export function getRouteElements(routes: RouteDescription[]): RouteElement[] {
  const protectedRoutes = routes.map((route) => {
    if (route.type === 'group') {
      return getRouteElements(route.views);
    }
    const { path, component } = route;
    return { path, component };
  });

  return protectedRoutes.flat().filter((route): route is RouteElement => !!route);
}

export function getActiveRouteName(routes: RouteDescription[], initialRoute: string): string {
  const activeRoute = initialRoute;
  for (let i = 0; i < routes.length; i++) {
    const route = routes[i];
    if (route.type === 'group') {
      const activeRouteInGroup = getActiveRouteName(route.views, initialRoute);
      if (activeRouteInGroup !== activeRoute) {
        return activeRouteInGroup;
      }
    } else if (utils.getLocation().href.indexOf(route.path) !== -1) {
      return routes[i].fullName;
    }
  }
  return activeRoute;
}

export function isActiveRoute(path: string): boolean {
  return utils.getLocation().hash.indexOf(path) !== -1;
}

export function getLocation() {
  return window.location;
}

const utils = {
  getRouteElements,
  getActiveRouteName,
  isActiveRoute,
  getLocation,
};

export default utils;
