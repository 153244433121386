// import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
// import { CssBaseline } from '@mui/material';
import { StylesProvider } from '@mui/styles';
import * as Sentry from '@sentry/browser';

// import { Integrations as ApmIntegrations } from '@sentry/apm';

import { AuthProvider } from 'hooks';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { store, persistor } from './redux/store';
import { App } from './App';

import 'assets/scss/material-dashboard-pro-react.scss';

Sentry.init({
  dsn: 'https://8471cf36e4124ba5b9c6c82c193a450a@o391453.ingest.sentry.io/5237583',
  integrations: [
    // new ApmIntegrations.Tracing({
    //   tracingOrigins: ['https://us-central1-sunny-196f4.cloudfunctions.net/api'],
    // }),
  ],
  tracesSampleRate: 0.25,
});

// if (process.env.NODE_ENV !== 'production') {
// const whyDidYouRender = require('@welldone-software/why-did-you-render');
// whyDidYouRender(React);
// }

// // Detects if device is on iOS
// const isIos = () => {
//   const userAgent = window.navigator.userAgent.toLowerCase();
//   return /iphone|ipad|ipod/.test( userAgent );
// }
// // Detects if device is in standalone mode
// const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

// // Checks if should display install popup notification:
// if (isIos() && !isInStandaloneMode()) {
//   this.setState({ showInstallMessage: true });
// }
const defaultTheme = createTheme({});

// Todo: figure out if you should use <CssBaseline />. However, it seems to be causing styling issues

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <HashRouter>
          <ThemeProvider theme={defaultTheme}>
            <StyledEngineProvider injectFirst>
              <StylesProvider>
                {/* <CssBaseline /> */}
                <AuthProvider>
                  <App />
                </AuthProvider>
              </StylesProvider>
            </StyledEngineProvider>
          </ThemeProvider>
        </HashRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

if (process.env.NODE_ENV === 'production') {
  // Learn more about service workers: https://cra.link/PWA
  // Be sure to always use an incognito window to avoid complications with your browser cache.
  serviceWorkerRegistration.register();
}

// Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// reportWebVitals(console.log);

// Hot Module Replace (HMR) - Remove this snippet to remove HMR
// Learn more: https://www.snowpack.dev/#hot-module-replacement
// if (import.meta.hot) {
//   import.meta.hot.accept();
// }
