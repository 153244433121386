// Icons
import ImageIcon from '@mui/icons-material/Image';

// pages
import {
  LoginPageHookContainer as LoginPage,
  RegisterPageContainer as RegisterPage,
} from 'apps/pages';

import { RouteDescription } from 'components';

export const authRouteDescriptions: RouteDescription[] = [
  {
    type: 'group',
    fullName: 'Pages',
    icon: ImageIcon,
    collapsed: true,
    views: [
      {
        type: 'node',
        path: 'login',
        fullName: 'Login Page',
        miniName: '-',
        component: () => <LoginPage />,
      },
      {
        type: 'node',
        path: 'register',
        fullName: 'Register Page',
        miniName: '-',
        component: () => <RegisterPage />,
      },
    ],
  },
];
