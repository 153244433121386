import React from 'react';
import classNames from 'classnames';

import useStyles from './CardBody.styles';

export interface CardBodyProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'color'> {
  className?: string;
  children?: any;
  plain?: boolean;
  background?: boolean;
  formHorizontal?: boolean;
  pricing?: boolean;
  signUp?: boolean;
  color?: boolean;
  profile?: boolean;
  calendar?: boolean;
}

export function CardBody({
  className,
  children,
  background,
  plain,
  formHorizontal,
  pricing,
  signUp,
  color,
  profile,
  calendar,
  ...rest
}: CardBodyProps) {
  const classes = useStyles();

  const cardBodyClasses = classNames({
    [classes.cardBody]: true,
    [classes.cardBodyBackground]: background,
    [classes.cardBodyPlain]: plain,
    [classes.cardBodyFormHorizontal]: formHorizontal,
    [classes.cardPricing]: pricing,
    [classes.cardSignUp]: signUp,
    [classes.cardBodyColor]: color,
    [classes.cardBodyProfile]: profile,
    [classes.cardBodyCalendar]: calendar,
    [className ? className : '']: className !== undefined,
  });
  return (
    <div className={cardBodyClasses} {...rest}>
      {children}
    </div>
  );
}
