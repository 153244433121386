/*
 * This hook makes it easy to see which prop changes are causing a component to re-render.
 * If a function is particularly expensive to run and you know it renders the same results
 * given the same props you can use the React.memo higher order component, as we've done
 * with the Counter component in the below example. In this case if you're still seeing
 * re-renders that seem unnecessary you can drop in the useWhyDidYouUpdate hook and check
 * your console to see which props changed between renders and view their previous/current
 * values. Pretty nifty huh?
 *
 * A huge thanks to Bruno Lemos for the idea and original code. You can also see it in
 * action in the [CodeSandbox demo](https://codesandbox.io/s/kx83n7201o).
 *
 * Usage:
 *   // Let's pretend this <Counter> component is expensive to re-render so ...
 *   // ... we wrap with React.memo, but we're still seeing performance issues :/
 *   // So we add useWhyDidYouUpdate and check our console to see what's going on.
 *   const Counter = React.memo((props) => {
 *     useWhyDidYouUpdate("Counter", props);
 *     return <div style={props.style}>{props.count}</div>;
 *   });
 *
 *   function App() {
 *     const [count, setCount] = useState(0);
 *     const [userId, setUserId] = useState(0);
 *
 *     // Our console output tells use that the style prop for <Counter> ...
 *     // ... changes on every render, even when we only change userId state by ...
 *     // ... clicking the "switch user" button. Oh of course! That's because the
 *     // ... counterStyle object is being re-created on every render.
 *     // Thanks to our hook we figured this out and realized we should probably ...
 *     // ... move this object outside of the component body.
 *     const counterStyle = {
 *       fontSize: "3rem",
 *       color: "red",
 *     };
 *
 *     return (
 *       <div>
 *         <div className="counter">
 *           <Counter count={count} style={counterStyle} />
 *           <button onClick={() => setCount(count + 1)}>Increment</button>
 *         </div>
 *         <div className="user">
 *           <img src={`http://i.pravatar.cc/80?img=${userId}`} />
 *           <button onClick={() => setUserId(userId + 1)}>Switch User</button>
 *         </div>
 *       </div>
 *     );
 *   }
 */

import React, { useEffect, useRef } from 'react';

export function flattenObject(
  ob: Record<string, any>,
  prefix: string = '',
  result: Record<string, any> | null = null,
) {
  result = result || {};

  // Preserve empty objects and arrays, they are lost otherwise
  if (prefix && typeof ob === 'object' && ob !== null && Object.keys(ob).length === 0) {
    result[prefix] = Array.isArray(ob) ? [] : {};
    return result;
  }

  prefix = prefix ? prefix + '.' : '';

  for (const i in ob) {
    if (Object.prototype.hasOwnProperty.call(ob, i)) {
      if (typeof ob[i] === 'object' && ob[i] !== null) {
        // Recursion on deeper objects
        flattenObject(ob[i], prefix + i, result);
      } else {
        result[prefix + i] = ob[i];
      }
    }
  }
  return result;
}

const disabledGlobally = true;
export interface WhyDidYouUpdateOptions {
  disabled?: boolean;
  showFirstRender?: boolean;
}

export function useWhyDidYouUpdate(
  name: string,
  props: Record<string, any>,
  options: WhyDidYouUpdateOptions = { disabled: false, showFirstRender: false },
) {
  // Get a mutable ref object where we can store props ...
  // ... for comparison next time this hook runs.
  const previousProps = useRef<any>();

  useEffect(() => {
    if (options.disabled || disabledGlobally) return;

    if (previousProps.current) {
      // Get all keys from previous and current props
      // const allKeys = Object.keys({ ...previousProps.current, ...props });
      const allKeys = Object.keys({
        ...flattenObject(previousProps.current),
        ...flattenObject(props),
      });

      // Use this object to keep track of changed props
      const changesObj: any = {};

      // Iterate through keys
      allKeys.forEach((key) => {
        // If previous is different from current
        if (previousProps.current[key] !== props[key]) {
          // Add to changesObj
          changesObj[key] = {
            from: previousProps.current[key],
            to: props[key],
          };
        }
      });

      // If changesObj not empty then output to console
      if (Object.keys(changesObj).length) {
        console.log('[why-did-you-update]', name, changesObj);
      } else {
        // const styles = [, 'background: yellow'].join(';');
        // const message = [
        //   ,
        //   name,

        // ].join(' ');
        console.log(
          '%c%s %c%s %c%s',
          'color: orange',
          '[why-did-you-update]',
          'color: orange; font-weight: bold',
          `${name}:`,
          'color: orange',
          'Re-rendered without any changes. Consider wrapping with React.memo.',
        );
      }
    }

    if (options.showFirstRender) {
      console.log('[why-did-you-update]', name, 'rendering for the first time.');
    }

    // Finally update previousProps with current props for next hook call
    previousProps.current = props;
  });
}

export const useWhyDidYouUpdateState = useWhyDidYouUpdate;

export interface WhyDidYouUpdatePropsOptions extends WhyDidYouUpdateOptions {
  useMemo?: boolean;
  hasMemo?: boolean;
}

export const defaultWhyDidYouUpdateOptions: WhyDidYouUpdatePropsOptions = {
  disabled: false,
  showFirstRender: false,
  useMemo: false,
  hasMemo: false,
};

export function withWhyDidYouUpdateProps<P>(
  WrappedComponent: (props: P) => JSX.Element | null,
  name: string,
  options = defaultWhyDidYouUpdateOptions,
) {
  const HOC = (props: P) => {
    useWhyDidYouUpdate(name, props as any, options);
    // @ts-ignore
    return <WrappedComponent {...props} />;
  };

  // Apply memo to HOC to test performance
  if (options.useMemo) {
    return React.memo(HOC);
  }

  // If the component is already memoized then we also apply memo
  // to the HOC to mimic the original component
  if (options.hasMemo) {
    return React.memo(HOC);
  }

  return HOC;
}
