import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  drawerWidth,
  drawerMiniWidth,
  transition,
  boxShadow,
  infoColor,
  whiteColor,
  blackColor,
  grayColor,
} from 'components/styles/common.styles';

export default makeStyles((theme: Theme) => ({
  blackBackground: {
    color: whiteColor,
    '&:after': {
      background: blackColor,
      opacity: '.8',
    },
  },
  blueBackground: {
    color: whiteColor,
    '&:after': {
      background: infoColor[0],
      opacity: '.93',
    },
  },
  whiteBackground: {
    color: grayColor[2],
    '&:after': {
      background: whiteColor,
      opacity: '.93',
    },
  },
  drawerPaper: {
    border: 'none',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    zIndex: 1032,
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    overflow: 'auto',
    ...boxShadow,
    width: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      position: 'fixed',
      height: '100%',
    },
    [theme.breakpoints.down('md')]: {
      width: drawerWidth,
      ...boxShadow,
      position: 'fixed',
      display: 'block',
      top: '0',
      height: 'calc(100% + env(safe-area-inset-top))',
      right: '0',
      left: 'auto',
      zIndex: '1032',
      visibility: 'visible',
      overflowY: 'visible',
      borderTop: 'none',
      textAlign: 'left',
      paddingRight: '0px',
      paddingLeft: '0',
      transform: `translate3d(${drawerWidth}px, 0, 0)`,
      ...transition,
    },
    '&:before,&:after': {
      position: 'absolute',
      zIndex: '3',
      width: '100%',
      height: 'calc(100% + env(safe-area-inset-top))',
      content: '""',
      display: 'block',
      top: '0',
    },
  },
  drawerPaperMini: {
    width: drawerMiniWidth + 'px !important',
    overflow: 'hidden',
  },
  drawerPaperRTL: {
    [theme.breakpoints.up('md')]: {
      left: 'auto !important',
      right: '0 !important',
    },
    [theme.breakpoints.down('sm')]: {
      left: '0  !important',
      right: 'auto !important',
    },
  },
}));
