import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { ListItem, ListItemText } from '@mui/material';
import { ForegroundColors } from 'components';
import { LinkIcon } from '../LinkIcon';
import { RouteNodeDescription } from '../common.types';
import { isActiveRoute } from '../../Sidebar.methods';
import useStyles from './LinkNode.styles';

export interface LinkNodeProps {
  route: RouteNodeDescription;
  color?: ForegroundColors;
  rtlMode: boolean;
  miniMode: boolean;
  onClick?: () => void;
}

export function LinkNode({
  route,
  color = 'blue',
  rtlMode,
  miniMode,
  onClick,
}: LinkNodeProps): JSX.Element {
  const classes = useStyles({ color, miniMode, rtlMode });

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (onClick) {
        e.preventDefault();
        onClick();
      }
    },
    [onClick],
  );

  return (
    <ListItem className={classes.item}>
      <NavLink
        to={route.path}
        className={cx(classes.itemLink, { [classes.itemLinkSelected]: isActiveRoute(route.path) })}
        onClick={handleClick}
      >
        <LinkIcon
          icon={route.icon}
          iconText={rtlMode ? route.miniNameRtl : route.miniName}
          rtlMode={rtlMode}
          flippedY={Boolean(route.iconFlipY)}
        />
        <ListItemText
          primary={rtlMode ? route.fullNameRtl : route.fullName}
          disableTypography={true}
          className={classes.itemText}
        />
      </NavLink>
    </ListItem>
  );
}
