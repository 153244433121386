import React, { lazy } from 'react';

// @mui/icons-material
import DashboardIcon from '@mui/icons-material/Dashboard';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import BuildIcon from '@mui/icons-material/Build';
import EjectIcon from '@mui/icons-material/Eject';
import MarketPlaceIcon from '@mui/icons-material/LocalGroceryStore';
import InsertChart from '@mui/icons-material/InsertChart';

// components
import { PlaceHolder, RouteDescriptions } from 'components';

// prefixes
const DASHBOARD_PATH = 'dashboard';
const COACHES_PATH = 'coaches';
const SERVICES_PATH = 'services';
const TOOLS_PATH = 'tools';
const SPIKES_PATH = 'spikes';

export const appRouteDescriptions: RouteDescriptions = [
  {
    type: 'node',
    path: DASHBOARD_PATH,
    fullName: 'Dashboard',
    icon: DashboardIcon,
    component: lazy(() => import('apps/root/dashboard')),
  },
  {
    type: 'node',
    path: 'trello-clone',
    fullName: 'TrelloClone',
    icon: InsertChart,
    component: lazy(() => import('apps/services/trello-clone')),
    iconFlipY: true,
  },
  // ###############################################################################################
  // Coaches
  // ###############################################################################################
  {
    type: 'group',
    collapsed: true,
    fullName: 'Coaches',
    icon: SupervisorAccountIcon,
    views: [
      {
        type: 'node',
        path: `${COACHES_PATH}/financial-adviser`,
        fullName: 'Financial Adviser',
        miniName: '-',
        component: () => (
          <PlaceHolder
            title="Financial Adviser"
            description="The purpose of FinancialAdviser is to help improve the user's financial
                     position by providing strategic and contextually-aware advice."
          />
        ),
      },
      {
        type: 'node',
        path: `${COACHES_PATH}/gratitude-helper`,
        fullName: 'Gratitude Helper',
        miniName: '-',
        component: lazy(() => import('apps/coaches/gratitude-helper')),
      },
      {
        type: 'node',
        path: `${COACHES_PATH}/life-manager`,
        fullName: 'Life Manager',
        miniName: '-',
        component: () => (
          <PlaceHolder
            title="Life Manager"
            description="The purpose of LifeManager is to ensure the user maximises their life-satisfaction by
                    maintaining a balanced lifestyle enforced by max/min time boundaries on each life domain."
          />
        ),
      },
      {
        type: 'node',
        path: `${COACHES_PATH}/meditation-coach`,
        fullName: 'Meditation Coach',
        miniName: '-',
        component: () => (
          <PlaceHolder
            title="Meditation Coach"
            description="The purpose of MeditationCoach is to coach the user to practice focusing their awareness
                    on various mental domains (body, thoughts, emotions, breath, sight, hearing, koans, etc)."
          />
        ),
      },
      {
        type: 'node',
        path: `${COACHES_PATH}/motivational-coach`,
        fullName: 'Motivational Coach',
        miniName: '-',
        component: () => (
          <PlaceHolder
            title="Motivational Coach"
            description="The purpose fo MotivationalCoach is to send context aware inspirational messages to the user
                    help nudge them towards more constructive and successful behaviour."
          />
        ),
      },
      {
        type: 'node',
        path: `${COACHES_PATH}/personal-dietitian`,
        fullName: 'Personal Dietitian',
        miniName: '-',
        component: lazy(() => import('apps/coaches/personal-dietitian')),
      },
      {
        type: 'node',
        path: `${COACHES_PATH}/personal-trainer`,
        fullName: 'Personal Trainer',
        miniName: '-',
        component: lazy(() => import('apps/coaches/personal-trainer')),
      },
      {
        type: 'node',
        path: `${COACHES_PATH}/relationship-manager`,
        fullName: 'Relationship Manager',
        miniName: '-',
        component: () => (
          <PlaceHolder
            title="Relationship Manager"
            description="The purpose of relationship manager is monitor the time and quality spent on one's relationships in
                    order to provide nudges, insights and suggestions that help improve and maintain the quality of
                    one's relationships."
          />
        ),
      },
      {
        type: 'node',
        path: `${COACHES_PATH}/sleep-coach`,
        fullName: 'Sleep Coach',
        miniName: '-',
        component: lazy(() => import('apps/coaches/sleep-coach')),
      },
    ],
  },
  // ###############################################################################################
  // Services
  // ###############################################################################################
  {
    type: 'group',
    collapsed: true,
    fullName: 'Services',
    icon: RoomServiceIcon,
    views: [
      {
        type: 'node',
        path: `${SERVICES_PATH}/apple-health-kit`,
        fullName: 'Apple HealthKit',
        miniName: '-',
        component: () => (
          <PlaceHolder
            title="Apple HealthKit"
            description="The purpose of AppleHealthKitService is to retrieve data stored in the HealthKit database so that
                    the user can query the state of their health using natural language and to provide an API for
                    other Horizon modules to utilise."
          />
        ),
      },
      {
        type: 'node',
        path: `${SERVICES_PATH}/calendar`,
        fullName: 'Calendar',
        miniName: '-',
        component: lazy(() => import('apps/services/calendar')),
      },
      {
        type: 'node',
        path: `${SERVICES_PATH}/contacts`,
        fullName: 'Contacts',
        miniName: '-',
        component: () => (
          <PlaceHolder
            title="Contacts"
            description="The purpose of ContactListService to help manage the user's personal contacts."
          />
        ),
      },
      {
        type: 'node',
        path: `${SERVICES_PATH}/email`,
        fullName: 'Email',
        miniName: '-',
        component: () => (
          <PlaceHolder
            title="Email"
            description="The purpose of EmailService is to help to user better manage their emails."
          />
        ),
      },
      {
        type: 'node',
        path: `${SERVICES_PATH}/entertainment`,
        fullName: 'Entertainment',
        miniName: '-',
        component: () => (
          <PlaceHolder
            title="Entertainment"
            description="The purpose of EntertainmentService is to provide personalised recommendations for
                    entertaining activities to do that are inline with one's interests and values."
          />
        ),
      },
      {
        type: 'node',
        path: `${SERVICES_PATH}/home`,
        fullName: 'Home',
        miniName: '-',
        component: () => (
          <PlaceHolder
            title="Home"
            description="The purpose of HomeService is to control and query smart home devices."
          />
        ),
      },
      {
        type: 'node',
        path: `${SERVICES_PATH}/knowledge-graph`,
        fullName: 'Knowledge Graph',
        miniName: '-',
        component: () => (
          <PlaceHolder
            title="Knowledge Graph"
            description="The purpose of KnowledgeGraphService is to provide google search functionality as a fallback
                    procedure to any unrecognized voice commands."
          />
        ),
      },
      {
        type: 'node',
        path: `${SERVICES_PATH}/location`,
        fullName: 'Location',
        miniName: '-',
        component: () => (
          <PlaceHolder
            title="Location"
            description="The purpose of LocationService to a provide a means to query the location history of the user,
                    times and distances of travel to a specified location and to search for venue of interest."
          />
        ),
      },
      {
        type: 'node',
        path: `${SERVICES_PATH}/pocket-smith`,
        fullName: 'Pocket Smith',
        miniName: '-',
        component: () => (
          <PlaceHolder
            title="Pocket Smith"
            description="The purpose of PocketSmithService is allow the user to query their past and current financial
                    situations and projected financial trends."
          />
        ),
      },
      {
        type: 'node',
        path: `${SERVICES_PATH}/rescue-time`,
        fullName: 'Rescue Time',
        miniName: '-',
        component: () => (
          <PlaceHolder
            title="Rescue Time"
            description="The purpose of RescueTimeService is to monitor the user's computer usage to gather insights
                    in order to provide helpful nudges to improve productivity."
          />
        ),
      },
      {
        type: 'node',
        path: `${SERVICES_PATH}/restaurant`,
        fullName: 'Restaurant',
        miniName: '-',
        component: () => (
          <PlaceHolder
            title="Restaurant"
            description="The purpose of RestaurantService is to provide recommendations on which restaurants to dine at
                    while offering a guided journey based on the user's needs, interests and values."
          />
        ),
      },
      {
        type: 'node',
        path: `${SERVICES_PATH}/shopping`,
        fullName: 'Shopping',
        miniName: '-',
        component: () => (
          <PlaceHolder
            title="Shopping"
            description="The purpose of ShoppingList automatically manage shopping lists stored in Trello."
          />
        ),
      },
      {
        type: 'node',
        path: `${SERVICES_PATH}/spotify`,
        fullName: 'Spotify',
        miniName: '-',
        component: () => (
          <PlaceHolder
            title="Spotify"
            description="The purpose of SpotifyService to provide a personalised DJ that plays favorite music according to
                    user's mood and preferences."
          />
        ),
      },
      {
        type: 'node',
        path: `${SERVICES_PATH}/weather`,
        fullName: 'Weather',
        miniName: '-',
        component: () => (
          <PlaceHolder
            title="Weather"
            description="The purpose of WeatherService is to provide weather forecast and recommendations on what to
                    wear or do for today, this weekend or next."
          />
        ),
      },
    ],
  },
  // ###############################################################################################
  // Tools
  // ###############################################################################################
  {
    type: 'group',
    collapsed: true,
    fullName: 'Tools',
    icon: BuildIcon,
    views: [
      {
        type: 'node',
        path: `${TOOLS_PATH}/journal`,
        fullName: 'Journal',
        miniName: '-',
        component: lazy(() => import('apps/services/journal')),
      },
      {
        type: 'node',
        path: `${TOOLS_PATH}/shopping-list`,
        fullName: 'Shopping List',
        miniName: '-',
        component: lazy(() => import('apps/tools/shopping-list')),
      },
      {
        type: 'node',
        path: `${TOOLS_PATH}/recipe-library`,
        fullName: 'Recipe Library',
        miniName: '-',
        component: lazy(() => import('apps/tools/recipe-library')),
      },
      {
        type: 'node',
        path: `${TOOLS_PATH}/administration`,
        fullName: 'Administration',
        miniName: '-',
        component: lazy(() => import('apps/tools/settings')),
      },
      {
        type: 'node',
        path: `${TOOLS_PATH}/body-tracker`,
        fullName: 'Body Tracker',
        miniName: '-',
        component: lazy(() => import('apps/tools/body-tracker')),
      },
      {
        type: 'node',
        path: `${TOOLS_PATH}/caffeine-tracker`,
        fullName: 'Caffeine Tracker',
        miniName: '-',
        component: lazy(() => import('apps/tools/caffeine-tracker')),
      },
      {
        type: 'node',
        path: `${TOOLS_PATH}/mood-tracker`,
        fullName: 'Mood Tracker',
        miniName: '-',
        component: lazy(() => import('apps/tools/mood-tracker')),
      },
      {
        type: 'node',
        path: `${TOOLS_PATH}/thought-tracker`,
        fullName: 'Thought Tracker',
        miniName: '-',
        component: () => (
          <PlaceHolder
            title="Thought Tracker"
            description="The purpose of ThoughtTracker is to monitor the quality of one's thought so that they
                    can improve self-awareness and self-control over their thinking behaviours."
          />
        ),
      },
    ],
  },
  // ###############################################################################################
  // Spikes
  // ###############################################################################################
  {
    type: 'group',
    collapsed: true,
    fullName: 'Spikes',
    icon: EjectIcon,
    views: [
      {
        type: 'node',
        path: `${SPIKES_PATH}/agent-viz`,
        fullName: 'Agent Machine Visualiser',
        miniName: '-',
        component: lazy(() => import('apps/spikes/agent-viz')),
      },
      {
        type: 'node',
        path: `${SPIKES_PATH}/chatgpt-playground`,
        fullName: 'ChatGPT Playground',
        miniName: '-',
        component: lazy(() => import('apps/spikes/chatgpt-playground')),
      },
      {
        type: 'node',
        path: `${SPIKES_PATH}/recipe-books/1`,
        fullName: 'Recipe Book',
        miniName: '-',
        component: lazy(() => import('apps/spikes/recipe-book')),
      },
      {
        type: 'node',
        path: `${SPIKES_PATH}/recipe/1`,
        fullName: 'Recipe',
        miniName: '-',
        component: lazy(() => import('apps/spikes/recipe')),
      },
      {
        type: 'node',
        path: `${SPIKES_PATH}/sentiment`,
        fullName: 'Sentiment',
        miniName: '-',
        component: lazy(() => import('apps/spikes/sentiment')),
      },
      {
        type: 'node',
        path: `${SPIKES_PATH}/tic-tac-toe`,
        fullName: 'Game',
        miniName: '-',
        component: lazy(() => import('apps/spikes/tic-tac-toe')),
      },
      {
        type: 'node',
        path: `${SPIKES_PATH}/todo`,
        fullName: 'Todo',
        miniName: '-',
        component: lazy(() => import('apps/spikes/firebase-hooks')),
      },
      {
        type: 'node',
        path: `${SPIKES_PATH}/django-react`,
        fullName: 'Django React',
        miniName: '-',
        component: lazy(() => import('apps/spikes/django-react')),
      },
      {
        type: 'node',
        path: `${SPIKES_PATH}/loading-component`,
        fullName: 'LoadingComponent ',
        miniName: '-',
        component: lazy(() => import('apps/spikes/loading-component')),
      },
      {
        type: 'node',
        path: `${SPIKES_PATH}/loading-indicator`,
        fullName: 'Loading Indicator',
        miniName: '-',
        component: lazy(() => import('apps/spikes/loading-indicator')),
      },
      {
        type: 'node',
        path: `${SPIKES_PATH}/calculator`,
        fullName: 'Calculator',
        miniName: '-',
        component: lazy(() => import('apps/spikes/calculator')),
      },
      {
        type: 'node',
        path: `${SPIKES_PATH}/cytoscape-react-graph`,
        fullName: 'CytoscapeReactGraph',
        miniName: '-',
        component: lazy(() => import('apps/spikes/cytoscape-react-graph')),
      },
      {
        type: 'node',
        path: `${SPIKES_PATH}/cytoscape-graph`,
        fullName: 'CytoscapeGraph',
        miniName: '-',
        component: lazy(() => import('apps/spikes/cytoscape-graph')),
      },
      {
        type: 'node',
        path: `${SPIKES_PATH}/simple-board`,
        fullName: 'Simple Board',
        miniName: '-',
        component: lazy(() => import('apps/spikes/simple-board')),
      },
      {
        type: 'node',
        path: `${SPIKES_PATH}/miner-bob`,
        fullName: 'Miner Bob',
        miniName: '-',
        component: lazy(() => import('apps/spikes/miner-bob')),
      },
    ],
  },
  // ###############################################################################################
  // Miscellanies
  // ###############################################################################################
  {
    type: 'node',
    path: 'market-place',
    fullName: 'Market Place',
    icon: MarketPlaceIcon,
    component: lazy(() => import('apps/root/market-place')),
  },
];
