import React from 'react';
import './SpinKit.styles.css';

const spinners = {
  plane: <div className="sk-plane"></div>,
  chase: (
    <div className="sk-chase">
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
      <div className="sk-chase-dot"></div>
    </div>
  ),
  bounce: (
    <div className="sk-bounce">
      <div className="sk-bounce-dot"></div>
      <div className="sk-bounce-dot"></div>
    </div>
  ),
  wave: (
    <div className="sk-wave">
      <div className="sk-wave-rect"></div>
      <div className="sk-wave-rect"></div>
      <div className="sk-wave-rect"></div>
      <div className="sk-wave-rect"></div>
      <div className="sk-wave-rect"></div>
    </div>
  ),
  pulse: <div className="sk-pulse"></div>,
  flow: (
    <div className="sk-flow">
      <div className="sk-flow-dot"></div>
      <div className="sk-flow-dot"></div>
      <div className="sk-flow-dot"></div>
    </div>
  ),
  swing: (
    <div className="sk-swing">
      <div className="sk-swing-dot"></div>
      <div className="sk-swing-dot"></div>
    </div>
  ),
  circle: (
    <div className="sk-circle">
      <div className="sk-circle-dot"></div>
      <div className="sk-circle-dot"></div>
      <div className="sk-circle-dot"></div>
      <div className="sk-circle-dot"></div>
      <div className="sk-circle-dot"></div>
      <div className="sk-circle-dot"></div>
      <div className="sk-circle-dot"></div>
      <div className="sk-circle-dot"></div>
      <div className="sk-circle-dot"></div>
      <div className="sk-circle-dot"></div>
      <div className="sk-circle-dot"></div>
      <div className="sk-circle-dot"></div>
    </div>
  ),
  circleFade: (
    <div className="sk-circle-fade">
      <div className="sk-circle-fade-dot"></div>
      <div className="sk-circle-fade-dot"></div>
      <div className="sk-circle-fade-dot"></div>
      <div className="sk-circle-fade-dot"></div>
      <div className="sk-circle-fade-dot"></div>
      <div className="sk-circle-fade-dot"></div>
      <div className="sk-circle-fade-dot"></div>
      <div className="sk-circle-fade-dot"></div>
      <div className="sk-circle-fade-dot"></div>
      <div className="sk-circle-fade-dot"></div>
      <div className="sk-circle-fade-dot"></div>
      <div className="sk-circle-fade-dot"></div>
    </div>
  ),
  grid: (
    <div className="sk-grid">
      <div className="sk-grid-cube"></div>
      <div className="sk-grid-cube"></div>
      <div className="sk-grid-cube"></div>
      <div className="sk-grid-cube"></div>
      <div className="sk-grid-cube"></div>
      <div className="sk-grid-cube"></div>
      <div className="sk-grid-cube"></div>
      <div className="sk-grid-cube"></div>
      <div className="sk-grid-cube"></div>
    </div>
  ),
  fold: (
    <div className="sk-fold">
      <div className="sk-fold-cube"></div>
      <div className="sk-fold-cube"></div>
      <div className="sk-fold-cube"></div>
      <div className="sk-fold-cube"></div>
    </div>
  ),
  wander: (
    <div className="sk-wander">
      <div className="sk-wander-cube"></div>
      <div className="sk-wander-cube"></div>
      <div className="sk-wander-cube"></div>
    </div>
  ),
};

export interface SpinKitProps {
  variant: keyof typeof spinners;
  color?: string;
  size?: number;
}

export function SpinKit({ variant, color = '#333', size = 40 }: SpinKitProps) {
  //@ts-ignore
  return <div style={{ '--sk-color': color, '--sk-size': `${size}px` }}>{spinners[variant]}</div>;
}
