import React, { useState } from 'react';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import { Collapse, List, ListItem, ListItemText } from '@mui/material';
import { ForegroundColors } from 'components/styles/common.types';
import {
  LinkIcon,
  LinkNode,
  RouteGroupDescription,
  RouteNodeDescription,
  isRouteNode,
} from '../index';
import useStyles from './LinkGroup.styles';

export interface LinkGroupProps {
  route: RouteGroupDescription | RouteNodeDescription;
  color: ForegroundColors;
  rtlMode: boolean;
  miniMode: boolean;
}

export const LinkGroup = React.memo(
  ({ route, color, rtlMode, miniMode }: LinkGroupProps): JSX.Element => {
    const classes = useStyles({ rtlMode, miniMode });
    const [expanded, setExpanded] = useState(false);

    if (isRouteNode(route)) {
      return <LinkNode route={route} color={color} rtlMode={rtlMode} miniMode={miniMode} />;
    }

    return (
      <ListItem className={classes.item}>
        <NavLink
          to={'#'}
          className={classes.itemLink}
          onClick={(e) => {
            e.preventDefault();
            setExpanded(!expanded);
          }}
        >
          <LinkIcon
            icon={route.icon}
            rtlMode={rtlMode}
            iconText={rtlMode ? route.miniNameRtl : route.miniName}
          />
          <ListItemText
            className={classes.itemText}
            primary={rtlMode ? route.fullNameRtl : route.fullName}
            secondary={<div className={cx(classes.caret, { [classes.caretActive]: expanded })} />}
            disableTypography={true}
          />
        </NavLink>
        <Collapse in={expanded} unmountOnExit>
          <List className={classes.list}>
            {route.views.map((view) => (
              <LinkGroup
                key={view.fullName}
                route={view}
                color={color}
                rtlMode={rtlMode}
                miniMode={miniMode}
              />
            ))}
          </List>
        </Collapse>
      </ListItem>
    );
  },
);
