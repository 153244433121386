/*
 * If you find yourself adding a lot of event listeners using useEffect you might consider
 * moving that logic to a custom hook.In the recipe below we create a useEventListener hook
 * that handles checking if addEventListener is supported, adding the event listener, and
 * removal on cleanup.See it in action in the CodeSandbox demo.
 *
 * Usage:
 *   function App() {
 *     // State for storing mouse coordinates
 *     const [coords, setCoords] = useState({ x: 0, y: 0 });
 *
 *     // Event handler utilizing useCallback ...
 *     // ... so that reference never changes.
 *     const handler = useCallback(
 *       ({ clientX, clientY }) => {
 *         // Update coordinates
 *         setCoords({ x: clientX, y: clientY });
 *       },
 *       [setCoords]
 *     );
 *
 *     // Add event listener using our hook
 *     useEventListener("mousemove", handler);
 *
 *     return (
 *       <h1>
 *         The mouse position is ({coords.x}, {coords.y})
 *       </h1>
 *     );
 *   }
 *
 * Also check out:
 *
 *   - donavon/use-event-listener - https://github.com/donavon/use-event-listener
 *     > Original source for this hook available as a library
 */

import { RefObject, useEffect, useRef } from 'react';

export function useEventListener<T extends HTMLElement = HTMLDivElement>(
  eventName: keyof WindowEventMap | string, // string to allow custom event
  handler: (event: Event) => void,
  element?: RefObject<T>,
) {
  // Create a ref that stores handler
  const savedHandler = useRef<(event: Event) => void>();

  useEffect(
    () => {
      // Define the listening target
      const targetElement: T | Window = element?.current || window;
      if (!(targetElement && targetElement.addEventListener)) {
        return;
      }

      // Update saved handler if necessary
      if (savedHandler.current !== handler) {
        savedHandler.current = handler;
      }

      // Create event listener that calls handler function stored in ref
      const eventListener = (event: Event) => {
        // eslint-disable-next-line no-extra-boolean-cast
        if (!!savedHandler?.current) {
          savedHandler.current(event);
        }
      };

      targetElement.addEventListener(eventName, eventListener);

      // Remove event listener on cleanup
      return () => {
        targetElement.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element, handler], // Re-run if eventName or element changes
  );
}
