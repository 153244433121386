/*
 * A really common need is to get the current size of the browser window. This hook
 * returns an object containing the window's width and height. If executed server-side
 * (no window object) the value of width and height will be undefined.
 *
 * Usage:
 *   function App() {
 *     const size: Size = useWindowSize();
 *     return (
 *       <div>
 *         {size.width}px / {size.height}px
 *       </div>
 *     );
 *   }
 */

import { useState, useLayoutEffect } from 'react';
import { useEventListener } from './use-event-listener.hook';

// Define general type for useWindowSize hook, which includes width and height
interface WindowSize {
  width: number | undefined;
  height: number | undefined;
}

export function useWindowSize(): WindowSize {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: undefined,
    height: undefined,
  });

  // Handler to call on window resize
  function handleResize() {
    // Set window width/height to state
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  // Add event listener
  useEventListener('resize', handleResize);

  useLayoutEffect(() => {
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
