import React from 'react';

import { SpinnerContainer, SpinnerOverlay } from './Spinner.styles';

export interface SpinnerProps {
  backgroundColor?: string;
}

export function Spinner({ backgroundColor }: SpinnerProps): JSX.Element {
  return (
    <SpinnerOverlay backgroundColor={backgroundColor}>
      <SpinnerContainer />
    </SpinnerOverlay>
  );
}
