import React, { Fragment } from 'react';
import { GridItem, GridContainer } from 'components';

export type PlaceHolderProps = {
  title?: string;
  description?: string;
};

export function PlaceHolder({ title, description }: PlaceHolderProps): JSX.Element {
  return (
    <GridContainer justifyContent="center">
      <GridItem xs={12} sm={6} md={6} lg={6}>
        <div style={{ textAlign: 'center' }}>
          {title || description ? (
            <>
              <h3>{title}</h3>
              <h4>{description}</h4>
              <p>Coming soon...</p>
            </>
          ) : (
            <h4>Please add some content...</h4>
          )}
        </div>
      </GridItem>
    </GridContainer>
  );
}
