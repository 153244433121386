import React, { useEffect, Suspense, useState, useCallback } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { useAppDispatch } from 'redux/hooks';
import { reauthenticateCurrentUser } from 'redux/user/user.actions';

import { ErrorBoundary, LoadingScreen, Spinner } from 'components';
import { useAuth, useWindowSize } from 'hooks';
import { AdminLayout, AuthLayout, mdBreakpoint } from 'layouts';

import {
  appRouteDescriptions,
  authRouteDescriptions,
  userRouteDescriptions,
  getRouteElements,
} from 'routes';

import './index.css';

// export const ComponentThatThrows = () => {
//   throw new Error('Oh no...');
// };

const loginUrl = '/auth/login';
const redirectAppUrl = '/apps/trello-clone';

const allRouteDescriptions = [...appRouteDescriptions, userRouteDescriptions];

export function App() {
  const dispatch = useAppDispatch();
  const { user: authenticatedUser } = useAuth();
  const [miniMode, setMiniMode] = useState(isMobile() ? false : true);

  const { width } = useWindowSize();
  const mobileView = (width && width < mdBreakpoint) || false;
  const sidebarVisible = !mobileView;

  useEffect(() => {
    dispatch(reauthenticateCurrentUser());
  }, [dispatch]);

  const handleMiniModeChange = useCallback((value: boolean) => {
    setMiniMode(value);
  }, []);

  if (authenticatedUser === undefined) {
    return <LoadingScreen />;
  }

  const user =
    authenticatedUser !== null
      ? {
          id: authenticatedUser.uid,
          displayName: authenticatedUser.displayName,
          email: authenticatedUser.email,
        }
      : null;

  return (
    <ErrorBoundary user={user} isFullScreen>
      {/* <button onClick={methodDoesNotExist}>Break the world</button> */}
      {/* <ComponentThatThrows /> */}
      <Routes>
        <Route path="/auth" element={<AuthLayout redirectTo={redirectAppUrl} />}>
          {getRouteElements(authRouteDescriptions).map((route) => {
            const { path, component: Component } = route;
            return <Route key={path} path={path} element={<Component />} />;
          })}
          <Route index element={<Navigate to={loginUrl} />} />
        </Route>
        <Route
          path="/apps"
          element={
            <AdminLayout
              redirectTo={loginUrl}
              routeDescriptions={appRouteDescriptions}
              mobileView={mobileView}
              miniMode={miniMode}
              onMiniModeChange={handleMiniModeChange}
            />
          }
        >
          {getRouteElements(allRouteDescriptions).map((route) => {
            const { path, component: Component } = route;
            return (
              <Route
                key={path}
                path={path}
                element={
                  <ErrorBoundary user={user} isFullScreen>
                    <Suspense fallback={<Spinner />}>
                      <Component
                        user={user}
                        sidebarVisible={sidebarVisible}
                        sidebarCollapsed={miniMode}
                      />
                    </Suspense>
                  </ErrorBoundary>
                }
              />
            );
          })}
          <Route index element={<Navigate to={redirectAppUrl} />} />
          <Route path="*" element={<AppNotFound />} />
        </Route>
        <Route path="*" element={<Navigate to={loginUrl} />} />
      </Routes>
    </ErrorBoundary>
  );
}

export function AppNotFound() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <h3>App not found</h3>
    </div>
  );
}

// Todo: move this into a utils file
function isMobile() {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
}
