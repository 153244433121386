export interface RouteBaseDescription {
  fullName: string;
  miniName?: string;
  fullNameRtl?: string;
  miniNameRtl?: string;
  icon?: any;
  iconFlipY?: boolean;
}

export interface RouteGroupDescription extends RouteBaseDescription {
  type: 'group';
  collapsed: boolean;
  views: RouteDescription[];
}

export interface RouteNodeDescription extends RouteBaseDescription {
  type: 'node';
  path: string;
  component: (() => JSX.Element) | React.LazyExoticComponent<(props: any) => JSX.Element>;
}

export type RouteDescription = RouteNodeDescription | RouteGroupDescription;

export type RouteDescriptions = RouteDescription[];

export interface RouteElement {
  path: string;
  component: (() => JSX.Element) | React.LazyExoticComponent<(props: any) => JSX.Element>;
}

// Todo: delete this, if not used
export const isRouteGroup = (route: RouteDescription): route is RouteGroupDescription => {
  return (route as RouteGroupDescription).type === 'group';
};

// Todo: delete this, if not used
export const isRouteNode = (route: RouteDescription): route is RouteNodeDescription => {
  return (route as RouteNodeDescription).type === 'node';
};
