import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import { AuthNavbar, Footer } from 'components';
import { useAuth, useRouter } from 'hooks';
import { authRouteDescriptions, getActiveRouteName, isActiveRoute } from 'routes';
import useStyles from './AuthLayout.styles';

// Images
import registerBackgroundImage from 'assets/img/register.jpeg';
import loginBackgroundImage from 'assets/img/login.jpeg';
import lockBackgroundImage from 'assets/img/lock.jpeg';
import errorBackgroundImage from 'assets/img/clint-mckoy.jpg';
import pricingBackgroundImage from 'assets/img/bg-pricing.jpeg';

export interface AuthLayoutProps {
  logoText?: string;
  redirectTo: string;
}

function getBackgroundImage() {
  const backgroundImageMap = {
    register: registerBackgroundImage,
    login: loginBackgroundImage,
    pricing: pricingBackgroundImage,
    lock: lockBackgroundImage,
    error: errorBackgroundImage,
  };

  const defaultPageImage = pricingBackgroundImage;
  const backgroundImage = Object.entries(backgroundImageMap).reduce(
    (selectedPageImage, [pagePath, pageImage]) => {
      return isActiveRoute(pagePath) ? pageImage : selectedPageImage;
    },
    defaultPageImage,
  );

  return `url(${backgroundImage})`;
}

export function AuthLayout({ logoText = 'Horizon', redirectTo }: AuthLayoutProps): JSX.Element {
  const classes = useStyles();
  const { user } = useAuth();
  const { pathname } = useRouter();

  if (user && pathname.includes('/auth')) {
    return <Navigate to={redirectTo} />;
  }

  return (
    <div>
      <AuthNavbar brandText={getActiveRouteName(authRouteDescriptions, logoText)} brandUrl="#" />
      <div className={classes.wrapper}>
        <div className={classes.fullPage} style={{ backgroundImage: getBackgroundImage() }}>
          <Outlet />
          <Footer white />
        </div>
      </div>
    </div>
  );
}
