import { createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import { createLogger } from 'redux-logger';
// import logger from 'redux-logger';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';

import { rootReducer } from './root.reducer';
import { regeneratingRootSaga, rootSaga } from './root.saga';

const regenerating = true;
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, thunk];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(
    createLogger({
      // collapse actions that don't have errors
      collapsed: (getState, action, logEntry) => !logEntry || !logEntry.error,
    }) as any,
  );
}

export const store = createStore(rootReducer, applyMiddleware(...middlewares));

if (regenerating) {
  sagaMiddleware.run(regeneratingRootSaga);
} else {
  sagaMiddleware.run(rootSaga);
}

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
