import React from 'react';
import { AllColors } from '../styles/common.types';
import useStyles from './InfoArea.styles';

export type InfoAreaProps = {
  title: string;
  description: string;
  iconColor?: AllColors;
  icon: any;
};

export function InfoArea({
  title,
  description,
  iconColor = 'gray',
  icon: IconComponent,
}: InfoAreaProps) {
  const classes = useStyles();
  return (
    <div className={classes.infoArea}>
      <div className={classes.iconWrapper + ' ' + classes[iconColor]}>
        <IconComponent className={classes.icon} />
      </div>
      <div className={classes.descriptionWrapper}>
        <h4 className={classes.title}>{title}</h4>
        <p className={classes.description}>{description}</p>
      </div>
    </div>
  );
}
