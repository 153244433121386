import React, { useState } from 'react';
import classNames from 'classnames';
import SwipeableViews from 'react-swipeable-views';
import { Tab, Tabs } from '@mui/material';

import { GridContainer, GridItem } from 'components';
import { MainColors } from '../styles/common.types';
import useStyles from 'components/NavPills/NavPills.styles';

export type NavPillsProps = {
  initialTabIndex?: number;
  tabs: {
    tabButton: string;
    tabIcon?: any;
    tabContent: any;
  }[];
  color?: MainColors;
  direction?: string;
  horizontal?: {
    tabsGrid: any;
    contentGrid: any;
  };
  alignCenter?: boolean;
};

export function NavPills({
  initialTabIndex = 0,
  tabs,
  direction,
  color = 'primary',
  horizontal,
  alignCenter,
}: NavPillsProps): JSX.Element {
  const classes = useStyles();
  const [active, setActive] = useState(initialTabIndex);

  const handleChange = (event: any, index: number) => {
    setActive(index);
  };

  const handleChangeIndex = (index: number) => {
    setActive(index);
  };

  const flexContainerClasses = classNames({
    [classes.flexContainer]: true,
    [classes.horizontalDisplay]: horizontal !== undefined,
  });

  const tabButtons = (
    <Tabs
      classes={{
        root: classes.root,
        fixed: classes.fixed,
        flexContainer: flexContainerClasses,
        indicator: classes.displayNone,
      }}
      value={active}
      onChange={handleChange}
      centered={alignCenter}
    >
      {tabs.map((prop, key) => {
        var icon = {};
        // if (prop.tabIcon !== undefined) {
        //   icon['icon'] = <prop.tabIcon className={classes.tabIcon} />;
        // }
        const pillsClasses = classNames({
          [classes.pills]: true,
          [classes.horizontalPills]: horizontal !== undefined,
          [classes.pillsWithIcons]: prop.tabIcon !== undefined,
        });
        return (
          <Tab
            label={prop.tabButton}
            key={key}
            {...icon}
            classes={{
              root: pillsClasses,
              selected: classes[color],
            }}
          />
        );
      })}
    </Tabs>
  );

  const tabContent = (
    <div className={classes.contentWrapper}>
      <SwipeableViews
        axis={direction === 'rtl' ? 'x-reverse' : 'x'}
        index={active}
        onChangeIndex={handleChangeIndex}
        style={{ overflowY: 'hidden' }}
      >
        {tabs.map((prop, key) => {
          return (
            <div className={classes.tabContent} key={key}>
              {prop.tabContent}
            </div>
          );
        })}
      </SwipeableViews>
    </div>
  );

  return horizontal !== undefined ? (
    <GridContainer>
      <>
        <GridItem {...horizontal.tabsGrid}>{tabButtons}</GridItem>
        <GridItem {...horizontal.contentGrid}>{tabContent}</GridItem>
      </>
    </GridContainer>
  ) : (
    <div>
      {tabButtons}
      {tabContent}
    </div>
  );
}
