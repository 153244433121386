import React from 'react';
import classNames from 'classnames';
import { MainColors } from '../styles/common.types';
import useStyles from './CardHeader.styles';

export type CardHeaderProps = {
  className?: string;
  children?: any;
  plain?: boolean;
  color?: MainColors;
  image?: boolean;
  contact?: boolean;
  signUp?: boolean;
  stats?: boolean;
  icon?: boolean;
  text?: boolean;
};

export function CardHeader({
  className,
  children,
  plain,
  color,
  image,
  contact,
  signUp,
  stats,
  icon,
  text,
  ...rest
}: CardHeaderProps) {
  const classes = useStyles();

  const cardHeaderClasses = classNames({
    [classes.cardHeader]: true,
    [classes.warningCardHeader]: color === 'warning',
    [classes.successCardHeader]: color === 'success',
    [classes.dangerCardHeader]: color === 'danger',
    [classes.infoCardHeader]: color === 'info',
    [classes.primaryCardHeader]: color === 'primary',
    [classes.roseCardHeader]: color === 'rose',
    [classes.cardHeaderPlain]: plain,
    [classes.cardHeaderImage]: image,
    [classes.cardHeaderContact]: contact,
    [classes.cardHeaderSignUp]: signUp,
    [classes.cardHeaderStats]: stats,
    [classes.cardHeaderIcon]: icon,
    [classes.cardHeaderText]: text,
    [className ? className : '']: className !== undefined,
  });

  return (
    <div className={cardHeaderClasses} {...rest}>
      {children}
    </div>
  );
}
