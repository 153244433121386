import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ForegroundColors } from 'components';

import {
  primaryColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  roseColor,
  whiteColor,
  blackColor,
  grayColor,
  hexToRgb,
} from 'components/styles/common.styles';

import { item, itemLink, getItemText } from '../common.styles';

function getHexColor(color: ForegroundColors): string {
  const colorMap: Record<ForegroundColors, string> = {
    blue: infoColor[0],
    green: successColor[0],
    orange: warningColor[0],
    purple: primaryColor[0],
    red: dangerColor[0],
    rose: roseColor[0],
    white: '',
  };

  return colorMap[color];
}

function getBoxShadowStyle(hexColor: string) {
  const rgbBlack = hexToRgb(blackColor);
  const rgbColor = hexToRgb(hexColor);
  return `0 12px 20px -10px rgba(${rgbColor},.28), 0 4px 20px 0 rgba(${rgbBlack},.12), 0 7px 8px -5px rgba(${rgbColor},.2)`;
}

interface LinkNodeStylesProps {
  color: ForegroundColors;
  miniMode: boolean;
  rtlMode: boolean;
}

export default makeStyles<Theme, LinkNodeStylesProps>((theme: Theme) => ({
  item,
  itemLink,
  itemText: ({ miniMode, rtlMode }) => {
    return getItemText({ miniMode, rtlMode });
  },
  itemLinkSelected: ({ color }) => {
    const hexColor = getHexColor(color);
    if (color === 'white') {
      return {
        '&,&:hover,&:focus': {
          color: grayColor[2],
          backgroundColor: whiteColor,
          boxShadow: getBoxShadowStyle(grayColor[0]),
        },
      };
    }

    return {
      '&,&:hover,&:focus': {
        color: whiteColor,
        backgroundColor: hexColor,
        boxShadow: getBoxShadowStyle(hexColor),
      },
    };
  },
}));
