import React, { useState } from 'react';

import defaultImage from 'assets/img/default-avatar.png';

import { FileUpload } from './CustomUpload.types';

export function PictureUpload() {
  const [fileUpload, setFileUpload] = useState<FileUpload>({
    file: null,
    imagePreviewUrl: defaultImage,
  });

  const handleImageChange = (e: any) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFileUpload({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  // const handleSubmit = (e: any) => {
  //   e.preventDefault();
  //   // this.state.file is the file/image uploaded
  //   // in this function you can save the image (this.state.file) on form submit
  //   // you have to call it yourself
  // };

  return (
    <div className="picture-container">
      <div className="picture">
        <img src={fileUpload.imagePreviewUrl} className="picture-src" alt="..." />
        <input type="file" onChange={(e) => handleImageChange(e)} />
      </div>
      <h6 className="description">Choose Picture</h6>
    </div>
  );
}

export default PictureUpload;
