import React from 'react';
import MuiIcon from '@mui/material/Icon';
import useStyles from './LinkIcon.styles';

export interface LinkIconProps {
  icon?: any;
  iconText?: string;
  rtlMode?: boolean;
  flippedY?: boolean;
}

export const LinkIcon = React.memo(
  ({ icon: Icon, iconText, rtlMode = false, flippedY = false }: LinkIconProps): JSX.Element => {
    const classes = useStyles({ rtlMode, flippedY });

    const IconText = () => <div className={classes.iconText}>{iconText}</div>;

    const IconSvg = () => {
      return typeof Icon === 'string' ? (
        <MuiIcon className={classes.itemIcon}>{Icon}</MuiIcon>
      ) : (
        <Icon className={classes.itemIcon} />
      );
    };

    return (
      <div className={classes.iconContainer}>{Icon === undefined ? <IconText /> : <IconSvg />}</div>
    );
  },
);
