import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  clearfix: {
    '&:after,&:before': {
      display: 'table',
      content: '" "',
    },
    '&:after': {
      clear: 'both',
    },
  },
});

export function Clearfix() {
  const classes = useStyles();

  return <div className={classes.clearfix} />;
}
