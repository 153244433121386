import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export interface StyleProps {
  rtlMode: boolean;
  flippedY: boolean;
}

export default makeStyles<Theme, StyleProps>(() => ({
  itemIcon: ({ flippedY }) => ({
    color: 'inherit',
    opacity: '0.8',
    transform: `scaleY(${flippedY ? '-1' : '1'})`,
  }),
  iconContainer: ({ rtlMode }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '30px',
    height: '24px',
    marginLeft: rtlMode ? '0px' : '4px',
    marginRight: rtlMode ? '4px' : '0px',
  }),
  iconText: ({ rtlMode }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '26px',
    height: '24px',
    textAlign: rtlMode ? 'right' : 'left',
  }),
}));
