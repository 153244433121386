import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { list } from '../common.styles';

import { drawerMiniWidth } from 'components/styles/common.styles';

export default makeStyles((theme: Theme) => ({
  list,
  background: {
    position: 'absolute',
    zIndex: 1,
    height: '100%',
    width: '100%',
    display: 'block',
    top: '0',
    left: '0',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    transition: 'all 300ms linear',
  },
  wrapper: {
    position: 'relative',
    height: 'calc(100% - 75px)',
    overflow: 'auto',
    width: '260px',
    zIndex: 4,
    overflowScrolling: 'touch',
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    color: 'inherit',
    paddingBottom: '30px',
  },
  wrapperPaperMini: {
    width: drawerMiniWidth + 'px !important',
  },
  wrapperWithPerfectScrollbar: {
    overflow: 'hidden !important',
  },
}));
