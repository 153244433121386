import React from 'react';
import { makeStyles } from '@mui/styles';
import Grid, { GridProps } from '@mui/material/Grid';

const useStyle = makeStyles({
  grid: {
    margin: '0 -15px',
    width: 'calc(100% + 30px)',
    // '&:before,&:after':{
    //   display: 'table',
    //   content: '" "',
    // },
    // '&:after':{
    //   clear: 'both',
    // }
  },
});

export interface GridContainerProps extends GridProps {
  className?: string;
  // justify?: any;
}

export function GridContainer({ children, className, ...rest }: GridContainerProps) {
  const classes = useStyle();
  return (
    <Grid container {...rest} className={classes.grid + ' ' + className}>
      {children}
    </Grid>
  );
}
