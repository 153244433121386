import { styled } from '@mui/styles';

export const ErrorImageOverlay = styled(
  ({ isFullScreen, textColor, backgroundColor, ...other }: any) => <div {...other} />,
)({
  height: ({ isFullScreen }: any) =>
    isFullScreen ? '100vh' : 'calc(100vh - 70px)' /* minus hight of app header */,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  color: ({ textColor }) => textColor,
  backgroundColor: ({ backgroundColor }) => backgroundColor,
});

export const ErrorImageContainer = styled(({ imageUrl, ...other }: any) => <div {...other} />)({
  display: 'inline-block',
  backgroundImage: ({ imageUrl }: any) => `url(${imageUrl})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '40vh',
  height: '40vh',
});

export const ErrorImageText = styled('h2')({
  fontWeight: 400,
  fontSize: '28px',
});

export const ErrorText = styled('div')({
  fontSize: '16px',
  padding: '0 20%',
});

export const ErrorButton = styled(({ textColor, backgroundColor, ...other }: any) => (
  <div {...other} />
))({
  color: ({ textColor }: any) => textColor,
  backgroundColor: ({ backgroundColor }) => backgroundColor,
  fontSize: '16px',
  padding: '8px 16px',
  borderRadius: '3px',
  border: 'none',
  marginTop: '32px',
  cursor: 'pointer',
  '&:hover': {
    filter: 'brightness(110%)',
    'box-shadow': '0 1px 2px 1px rgba(0, 0, 0, 0.2)',
  },
});
