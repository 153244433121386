import firebase, { firebaseAuth, firestore } from '../firebase.core';
import { Timestamp, UserCredential } from '../firebase.core';

export interface IUserDetails {
  uid: string;
  displayName: string;
  email: string;
}

export async function createOrRetrieveUserProfileDocument<AdditionalData = any>(
  userDetails: IUserDetails,
  additionalData: AdditionalData,
): Promise<any> {
  if (!userDetails) return;

  const userRef = firestore.doc(`users/${userDetails.uid}`);
  const snapShot = await userRef.get();

  if (!snapShot.exists) {
    const { displayName, email } = userDetails;
    const createdAt = new Date();

    try {
      await userRef.set({
        displayName,
        email,
        createdAt,
        ...additionalData,
      });
    } catch (error) {
      console.error('error creating user', (error as any).message);
    }
  }

  return userRef;
}

export function getCurrentUser(): Promise<firebase.User | null> {
  return new Promise((resolve, reject): any => {
    const unsubscribe = firebaseAuth.onAuthStateChanged((userAuth) => {
      unsubscribe();
      resolve(userAuth);
    }, reject);
  });
}

export const googleProvider = new firebase.auth.GoogleAuthProvider();
googleProvider.addScope('profile');
googleProvider.addScope('email');
googleProvider.setCustomParameters({ prompt: 'select_account' });

export const signInWithGoogle = (): Promise<UserCredential> =>
  firebaseAuth.signInWithPopup(googleProvider);

export function getTimestamp(hasTimestamp: boolean): string {
  return hasTimestamp ? Timestamp.now().toDate().toISOString() : '';
}
