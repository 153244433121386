import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getCaret, caretActive, item, getItemText, itemLink, list } from '../common.styles';

interface LinkGroupStyleProps {
  miniMode: boolean;
  rtlMode: boolean;
}

export default makeStyles<Theme, LinkGroupStyleProps>((theme: Theme) => ({
  list,
  item,
  itemLink,
  caretActive,
  caret: ({ rtlMode: rtlActive }) => {
    return getCaret({ rtlMode: false });
  },
  itemText: ({ miniMode, rtlMode }) => {
    return getItemText({ miniMode, rtlMode });
  },
}));
