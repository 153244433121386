import { defaultFont, hexToRgb, grayColor } from 'components/styles/common.styles';

export interface ItemProps {
  miniMode: boolean;
  rtlMode: boolean;
}

export const list = {
  padding: '0',
  margin: '0',
  listStyle: 'none',
  color: 'inherit',
  '&:before,&:after': {
    display: 'table',
    content: '" "',
  },
  '&:after': {
    clear: 'both',
  },
} as const;

export function getCollapseItemMini({ rtlMode }: { rtlMode: boolean }) {
  return {
    color: 'inherit',
    ...defaultFont,
    textTransform: 'uppercase',
    width: '30px',
    marginLeft: rtlMode ? '30px' : '0px',
    marginRight: rtlMode ? '1px' : '15px',
    textAlign: 'center',
    letterSpacing: '1px',
    position: 'relative',
    float: rtlMode ? 'right' : 'left',
    display: 'inherit',
    transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
    fontSize: '14px',
  } as const;
}

export function getCollapseItemText({ miniMode, rtlMode }: ItemProps) {
  return {
    color: 'inherit',
    ...defaultFont,
    margin: '0',
    position: 'relative',
    fontSize: '14px',
    transform: 'translateX(0px)',
    transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
    whiteSpace: 'nowrap',
    display: 'block',
    textAlign: rtlMode ? 'right' : 'left',
    opacity: miniMode ? 0 : 1,
  } as const;
}

export const item = {
  color: 'inherit',
  position: 'relative',
  display: 'block',
  textDecoration: 'none',
  margin: '10px 0 0 0',
  padding: '0',
  width: '100%',
} as const;

export const itemLink = {
  ...defaultFont,
  transition: 'all 300ms linear',
  margin: '10px 15px 0',
  padding: '10px 10px',
  borderRadius: '3px',
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: 'transparent',
  alignItems: 'center',
  width: 'auto',
  '&:hover': {
    backgroundColor: `rgba(${hexToRgb(grayColor[17])}, 0.2)`,
    boxShadow: 'none',
  },
  '&,&:hover,&:focus': {
    color: 'inherit',
  },
} as const;

export function getItemText({ miniMode, rtlMode }: ItemProps) {
  return {
    ...defaultFont,
    display: 'flex',
    alignItems: 'center',
    margin: '0',
    transition: 'transform 300ms ease 0s, opacity 300ms ease 0s, width 300ms ease 0s',
    whiteSpace: 'nowrap',
    textAlign: rtlMode ? 'right' : 'left',
    opacity: miniMode ? 0 : 1,
    width: '100%',
    paddingRight: rtlMode ? '15px' : '0px',
    paddingLeft: rtlMode ? '0px' : '15px',
  } as const;
}

export function getCaret({ rtlMode }: { rtlMode: boolean }) {
  return {
    position: 'absolute',
    transition: 'all 150ms ease-in',
    display: 'inline-block',
    width: '0',
    height: '0',
    marginTop: '0px',
    marginLeft: '2px',
    borderTop: '4px solid',
    borderRight: '4px solid transparent',
    borderLeft: '4px solid transparent',
    left: rtlMode ? '10px' : 'auto',
    right: rtlMode ? 'auto' : '10px',
  } as const;
}

export const caretActive = {
  transform: 'rotate(180deg)',
} as const;

export const whiteAfter = {
  '&:after': {
    backgroundColor: 'hsla(0,0%,71%,.3) !important',
  },
};
