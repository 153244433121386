import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { NavbarColors } from 'components';
import {
  containerFluid,
  defaultFont,
  grayColor,
  getNavbarColor,
  getNavbarBoxShadow,
} from 'components/styles/common.styles';

export interface AuthNavbarStyleProps {
  color: NavbarColors;
  rtlMode: boolean;
}

export default makeStyles<Theme, AuthNavbarStyleProps>((theme: Theme) => ({
  appBar: ({ color }) => ({
    backgroundColor: getNavbarColor(color),
    ...getNavbarBoxShadow(color),
    borderBottom: '0',
    marginBottom: '0',
    position: 'absolute',
    width: '100%',
    paddingTop: '10px',
    zIndex: 1029,
    color: grayColor[6],
    border: '0',
    borderRadius: '3px',
    padding: '10px 0',
    transition: 'all 150ms ease 0s',
    minHeight: '50px',
    display: 'block',
  }),
  container: {
    ...containerFluid,
    minHeight: '50px',
  },
  flex: {
    flex: 1,
  },
  title: {
    ...defaultFont,
    lineHeight: '30px',
    fontSize: '18px',
    borderRadius: '3px',
    textTransform: 'none',
    color: 'inherit',
    paddingTop: '0.625rem',
    paddingBottom: '0.625rem',
    margin: '0 !important',
    letterSpacing: 'unset',
    '&:hover,&:focus': {
      background: 'transparent',
    },
  },
  sidebarMinimize: ({ rtlMode }) => ({
    float: 'left',
    padding: rtlMode ? '0 15px 0 0 !important' : '0 0 0 15px',
    display: 'block',
    color: grayColor[6],
  }),
  sidebarMiniIcon: {
    width: '20px',
    height: '17px',
  },
  appResponsive: {
    top: '8px',
  },
}));
