import { all, call, spawn } from 'redux-saga/effects';

export function regeneratingSaga(sagas: any) {
  return function* rootSaga() {
    yield all(
      sagas.map((saga: any) =>
        spawn(function* () {
          while (true) {
            try {
              yield call(saga);
              break;
            } catch (e) {
              console.log(e);
            }
          }
        }),
      ),
    );
  };
}

export const withCatchingReducer = (reducer: any) => (state: any, action: any) => {
  try {
    return reducer(state, action);
  } catch (e) {
    console.error(e);
    // console.log('Error in reducer');
    return state;
  }
};
