import React from 'react';
import * as Sentry from '@sentry/browser';

import { ErrorPage } from '../ErrorPage/ErrorPage.component';

/*
 * There are types of unhandled exceptions that can't be caught.
 *
 * These include unhandled exceptions thrown from:
 *
 *  - action creators that aren't thunks
 *  - event handlers in React components (onClick, onSubmit, etc.)
 *  - setTimeout or setInterval
 */

export type ErrorBoundaryState = {
  hasError: boolean;
  eventId: string | null;
  error: any | null;
};

export type ErrorBoundaryProps = {
  isFullScreen?: boolean;
  user: any;
};

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      error: null,
      eventId: null,
      hasError: false,
    };
  }

  static getDerivedStateFromError(error: ErrorBoundaryState) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ error, eventId });
      console.error(error);
    });
  }

  render() {
    const { isFullScreen, user } = this.props;
    const { eventId } = this.state;

    if (this.state.hasError) {
      // Render fallback UI
      return (
        <div>
          <ErrorPage
            isFullScreen={Boolean(isFullScreen)}
            eventId={eventId ? eventId : ''}
            user={user}
          />
        </div>
      );
    }

    // When there's not an error, render children untouched
    return this.props.children;
  }
}
