import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

const config = {
  apiKey: 'AIzaSyDu_tF-b4aazswJQPK2B9j5vi58k7XIjWU',
  // authDomain: 'sunny-196f4.firebaseapp.com',
  // authDomain: 'auth.sunnyai.net', // see: https://www.swkeever.com/firebase-auth
  authDomain: 'sunnyai.net', // see: https://cloud.google.com/identity-platform/docs/show-custom-domain
  databaseURL: 'https://sunny-196f4.firebaseio.com',
  projectId: 'sunny-196f4',
  storageBucket: 'sunny-196f4.appspot.com',
  messagingSenderId: '53326932418',
  appId: '1:53326932418:web:29897b72da233a51',
};

firebase.initializeApp(config);

export const firebaseApp = firebase;
export const firebaseAuth = firebase.auth();
export const firestore = firebase.firestore();
export const Timestamp = firebase.firestore.Timestamp;

// firebaseAuth.useEmulator('http://localhost:3000');
// firebaseAuth.useEmulator('http://localhost:9099');

// Todo: Add a check box on the login page asking if the user trusts the computer before enabling Firestore persistence
if (process.env.NODE_ENV === 'production') {
  firestore.settings({
    cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
  });
  firestore
    .enablePersistence({ synchronizeTabs: true })
    .then(() => {
      console.log('Firestore persistence enabled');
    })
    .catch((error) => {
      if (error.code === 'failed-precondition') {
        console.log(
          'Multiple tabs open, Firestore persistence can only be enabled in one tab at a a time.',
        );
      } else if (error.code === 'unimplemented') {
        console.log(
          'The current browser does not support all of the features required to enable Firestore persistence.',
        );
      }
    });
} else if (process.env.NODE_ENV === 'development') {
  console.log('Firestore persistence not available in development mode');
}

export type UserCredential = firebase.auth.UserCredential;
export type Firestore = firebase.firestore.Firestore;
// export type DocumentData = firebase.firestore.DocumentData;
export type Query = firebase.firestore.Query;
export type CollectionReference<DocumentData> =
  firebase.firestore.CollectionReference<DocumentData>;
export type DocumentReference<DocumentData> = firebase.firestore.DocumentReference<DocumentData>;
export type DocumentSnapshot<DocumentData> = firebase.firestore.DocumentSnapshot<DocumentData>;
export type QuerySnapshot<DocumentData> = firebase.firestore.QuerySnapshot<DocumentData>;
export type WriteBatch = firebase.firestore.WriteBatch;

export default firebase;
