import React, { useState } from 'react';
import { InputAdornment, Checkbox, FormControlLabel, Icon } from '@mui/material';
import { Button, Card, CardBody, CustomInput, GridContainer, GridItem, InfoArea } from 'components';

// Icons
import CheckIcon from '@mui/icons-material/Check';
import EmailIcon from '@mui/icons-material/Email';
import FaceIcon from '@mui/icons-material/Face';

import { productFeatureDescriptions } from './Register.data';
import useStyles from './Register.styles';

export interface RegisterPageProps {
  onSignUp: any;
}

export function RegisterPage({ onSignUp }: RegisterPageProps): JSX.Element {
  const classes = useStyles();
  const [displayName, setDisplayName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [checked, setChecked] = useState(false);

  const handleToggle = () => {
    setChecked(!checked);
  };

  const handleSignUp = () => {
    onSignUp(displayName, email, password);
  };

  return (
    <div className={classes.container}>
      <GridContainer sx={{ justifyContent: 'center' }}>
        <GridItem xs={12} sm={12} md={10}>
          <Card className={classes.cardSignUp}>
            <h2 className={classes.cardTitle}>Register</h2>
            <CardBody>
              <GridContainer sx={{ justifyContent: 'center' }}>
                <GridItem xs={12} sm={12} md={5}>
                  {productFeatureDescriptions.map(({ title, description, icon, color }) => (
                    <InfoArea
                      key={title}
                      title={title}
                      description={description}
                      icon={icon}
                      iconColor={color}
                    />
                  ))}
                </GridItem>
                <GridItem xs={12} sm={8} md={5}>
                  <div className={classes.center}>
                    <Button justIcon round color="twitter">
                      <i className="fab fa-twitter" />
                    </Button>
                    {` `}
                    <Button justIcon round color="dribbble">
                      <i className="fab fa-dribbble" />
                    </Button>
                    {` `}
                    <Button justIcon round color="facebook">
                      <i className="fab fa-facebook-f" />
                    </Button>
                    {` `}
                    <h4 className={classes.socialTitle}>or be classical</h4>
                  </div>
                  <form className={classes.form}>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment position="start" className={classes.inputAdornment}>
                            <FaceIcon className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: 'First Name...',
                        onChange: (e) => setDisplayName(e.target.value),
                      }}
                    />
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment position="start" className={classes.inputAdornment}>
                            <EmailIcon className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: 'Email...',
                        onChange: (e) => setEmail(e.target.value),
                      }}
                    />
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        startAdornment: (
                          <InputAdornment position="start" className={classes.inputAdornment}>
                            <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                          </InputAdornment>
                        ),
                        placeholder: 'Password...',
                        onChange: (e) => setPassword(e.target.value),
                      }}
                    />
                    <FormControlLabel
                      classes={{
                        root: classes.checkboxLabelControl,
                        label: classes.checkboxLabel,
                      }}
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => handleToggle()}
                          checkedIcon={<CheckIcon className={classes.checkedIcon} />}
                          icon={<CheckIcon className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      }
                      label={
                        <span>
                          I agree to the <a href="#pablo">terms and conditions</a>.
                        </span>
                      }
                    />
                    <div className={classes.center}>
                      <Button round color="primary" onClick={handleSignUp}>
                        Get started
                      </Button>
                    </div>
                  </form>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
