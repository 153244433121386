import React, { useEffect, useState } from 'react';
// import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
// import { firebaseApp, firebaseAuth } from 'firebase/firebase.core';

import { InputAdornment, Icon, Divider, Typography, Button } from '@mui/material';
import Face from '@mui/icons-material/Face';

import {
  Card,
  CardBody,
  CardHeader,
  CustomInput,
  CardFooter,
  GridContainer,
  GridItem,
  MainColors,
} from 'components';

import useStyles from './Login.styles';

// const uiConfig = {
//   // Popup signIn flow rather than redirect flow.
//   signInFlow: 'popup',
//   // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
//   signInSuccessUrl: '/signedIn',
//   // We will display Google and Facebook as auth providers.
//   signInOptions: [
//     firebaseApp.auth.GoogleAuthProvider.PROVIDER_ID,
//     firebaseApp.auth.FacebookAuthProvider.PROVIDER_ID,
//   ],
// };

type LoginPageInputs = {
  email: string;
  password: string;
};

export interface LoginPageProps {
  currentUser?: any;
  onSignInWithEmailAndPassword: (args: { email: string; password: string }) => void;
  onSignInWithGoogle: () => void;
  onSignOut: () => void;
  authenticationError?: boolean;
  color?: MainColors;
}

export function LoginPage({
  currentUser,
  onSignInWithEmailAndPassword,
  onSignInWithGoogle,
  onSignOut,
  authenticationError,
  color = 'rose',
}: LoginPageProps) {
  const classes = useStyles();
  const [isHidden, setIsHidden] = useState(true);
  const { control, handleSubmit } = useForm<LoginPageInputs>();

  useEffect(() => {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    const timeOutFunction = setTimeout(() => {
      setIsHidden(false);
    }, 700);

    return () => clearTimeout(timeOutFunction);
  }, []);

  const onFormSubmit: SubmitHandler<LoginPageInputs> = ({ email, password }, event) => {
    event?.preventDefault();
    onSignInWithEmailAndPassword({ email, password });
  };

  return (
    <GridContainer>
      <div className={classes.container}>
        <GridItem xs={12} sm={9} md={4}>
          <Card login className={isHidden ? classes.cardHidden : ''}>
            <CardHeader className={classes.cardHeader} color={color}>
              <h4 className={classes.cardTitle}>
                <b>Log in</b>
              </h4>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit(onFormSubmit)}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <CustomInput
                      id="email"
                      labelText="Email"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        ...field,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Face className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <CustomInput
                      id="password"
                      labelText="Password"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        ...field,
                        type: 'password',
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
                {authenticationError ? (
                  <p className={classes.errorMessage}>Invalid credentials. Failed to login.</p>
                ) : (
                  <div className={classes.noErrorSpacer} />
                )}
                <div className={classes.buttonsContainer}>
                  <Button type="submit" color="inherit" variant="outlined" size="medium" fullWidth>
                    Sign in
                  </Button>
                </div>
              </form>
            </CardBody>
            <CardFooter className={classes.cardFooter}>
              <div className={classes.buttonsContainer}>
                <Divider className={classes.divider}>
                  <Typography>or</Typography>
                </Divider>
                <Button onClick={onSignInWithGoogle} className={classes.googleButton}>
                  Sign in with Google
                </Button>
                <Button onClick={onSignOut}>Sign out</Button>
                {/* <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebaseAuth} /> */}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </div>
    </GridContainer>
  );
}
