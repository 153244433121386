import React from 'react';

import useStyles from './Typography.styles';

export interface MutedProps extends React.ComponentPropsWithRef<'div'> {
  children?: any;
}

export function Muted({ children }: MutedProps): JSX.Element {
  const classes = useStyles();
  return <div className={classes.defaultFontStyle + ' ' + classes.mutedText}>{children}</div>;
}
