import React, { useEffect, useRef, useCallback, useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import cx from 'classnames';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import Div100vh from 'react-div-100vh';
import MessengerCustomerChat from 'react-messenger-customer-chat';

import {
  AdminNavbar,
  BackgroundColors,
  ForegroundColors,
  RouteDescriptions,
  Sidebar,
} from 'components';
import { useAuth } from 'hooks';

import defaultLogoImage from 'assets/img/horizon-circle.png';
import defaultSidebarImage from 'assets/img/sidebar-2.jpg';

import useStyles from './AdminLayout.styles';

const isProductionMode = process.env.NODE_ENV === 'production';
let perfectScrollbar: PerfectScrollbar | null = null;

export const mdBreakpoint = 900;

export interface AdminLayoutProps {
  routeDescriptions: RouteDescriptions;
  redirectTo: string;
  logoText?: string;
  logoImage?: any;
  layoutPath?: string;
  userName?: string;
  sidebarImage?: string;
  sidebarHighlightColor?: ForegroundColors;
  sidebarBackgroundColor?: BackgroundColors;
  mobileView: boolean;
  miniMode: boolean;
  onMiniModeChange: (value: boolean) => void;
}

export function AdminLayout({
  routeDescriptions,
  redirectTo,
  logoText = 'Horizon',
  logoImage = defaultLogoImage,
  userName = 'Sergious79',
  sidebarImage = defaultSidebarImage,
  sidebarHighlightColor = 'blue',
  sidebarBackgroundColor = 'black',
  mobileView,
  miniMode,
  onMiniModeChange,
}: AdminLayoutProps) {
  const classes = useStyles();
  const auth = useAuth();
  const navigate = useNavigate();
  const mainPanelRef = useRef<any>(null);
  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    const resizeFunction = () => {
      if (window.innerWidth >= mdBreakpoint) {
        setMobileOpen(false);
      }
    };

    if (navigator.platform.indexOf('Win') > -1) {
      perfectScrollbar = new PerfectScrollbar(mainPanelRef.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = 'hidden';
    }
    window.addEventListener('resize', resizeFunction);

    return function cleanup() {
      if (perfectScrollbar) {
        perfectScrollbar.destroy();
      }
      window.removeEventListener('resize', resizeFunction);
    };
  });

  // componentDidUpdate(e) {
  //   if (e.history.location.pathname !== e.location.pathname) {
  //     this.mainPanel.current.scrollTop = 0;
  //     if (state.mobileOpen) {
  //       setState({ ...state, mobileOpen: false });
  //   }
  // }

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(!mobileOpen);
  }, [mobileOpen]);

  const handleSidebarToggle = useCallback((): void => {
    onMiniModeChange(!miniMode);
  }, [miniMode, onMiniModeChange]);

  const handleSignOut = useCallback((): void => {
    auth.signOut();
    navigate(redirectTo);
  }, [auth, navigate, redirectTo]);

  const mainPanelClasses = cx(classes.mainPanel, {
    [classes.mainPanelSidebarMini]: miniMode,
    [classes.mainPanelWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1,
  });

  if (!auth.user) {
    return <Navigate to={redirectTo} />;
  }

  return (
    <Div100vh className={classes.wrapper}>
      <Sidebar
        routes={routeDescriptions}
        open={mobileOpen}
        miniMode={mobileView ? false : miniMode}
        rtlMode={false}
        userName={userName}
        logoText={logoText}
        logoImage={logoImage}
        sidebarImage={sidebarImage}
        highlightColor={sidebarHighlightColor}
        backgroundColor={sidebarBackgroundColor}
        onDrawerToggle={handleDrawerToggle}
        onSidebarToggle={handleSidebarToggle}
        onLogout={handleSignOut}
      />
      <div className={mainPanelClasses} ref={mainPanelRef}>
        <div className={mobileView ? classes.content : classes.map}>
          {mobileView && (
            <AdminNavbar
              foregroundColor="transparent"
              miniMode={miniMode}
              rtlMode={false}
              brandText={logoText}
              onDrawerToggle={handleDrawerToggle}
              onSidebarMinimize={handleSidebarToggle}
            />
          )}
          <Outlet />
        </div>
      </div>
      {isProductionMode && (
        <MessengerCustomerChat
          pageId="1760225697577826"
          appId="1256877551043844"
          themeColor="#FD9810"
        />
      )}
    </Div100vh>
  );
}
