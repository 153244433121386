/*eslint-disable*/
import React from 'react';
import cx from 'classnames';

import { List, ListItem } from '@mui/material';

import { copyright, footerLinks } from './Footer.data';
import useStyles from './Footer.styles';

export type FooterProps = {
  fluid?: boolean;
  white?: boolean;
  rtlActive?: boolean;
};

export function Footer({ fluid, white, rtlActive }: FooterProps) {
  const classes = useStyles();

  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white,
  });

  var anchor =
    classes.a +
    cx({
      [' ' + classes.whiteColor]: white,
    });

  var block = cx({
    [classes.block]: true,
    [classes.whiteColor]: white,
  });

  return (
    <footer className={classes.footer}>
      <div className={container}>
        <div className={classes.left}>
          <List className={classes.list}>
            {footerLinks.map((link) => (
              <ListItem key={link.name} className={classes.inlineBlock}>
                <a href={link.url} className={block}>
                  {rtlActive ? link.nameRtl : link.name}
                </a>
              </ListItem>
            ))}
          </List>
        </div>
        <p className={classes.right}>
          &copy; {new Date().getFullYear()}{' '}
          <a href={copyright.companyHomeUrl} className={anchor} target="_blank">
            {rtlActive ? copyright.companyNameRlt : copyright.companyName}
          </a>
          {rtlActive ? copyright.sloganRlt : copyright.slogan}
        </p>
      </div>
    </footer>
  );
}
