import React from 'react';
import * as Sentry from '@sentry/browser';

import { selectRandomErrorPage } from './ErrorPage.methods';
import { errorPages } from './ErrorPage.data';

import {
  ErrorImageOverlay,
  ErrorImageContainer,
  ErrorImageText,
  ErrorText,
  ErrorButton,
} from './ErrorPage.styles';

export type ErrorPageProps = {
  isFullScreen: boolean;
  eventId: string;
  user: any;
  random?: boolean;
  pageId?: string;
};

export const ErrorPage = ({
  isFullScreen,
  eventId,
  user,
  random = true,
  pageId = 'page1',
}: ErrorPageProps) => {
  const page = random ? selectRandomErrorPage('broken') : errorPages[pageId];

  const userDetails = user ? { name: user.displayName, email: user.email } : undefined;

  const renderReportButton = () => {
    if (!eventId) return null;
    return (
      <ErrorButton
        textColor={page.backgroundColor} // invert colors
        backgroundColor={page.textColor} // invert colors
        onClick={() => {
          Sentry.showReportDialog({
            eventId,
            user: userDetails,
          });
        }}
      >
        Tell us what happen...
      </ErrorButton>
    );
  };

  return (
    <ErrorImageOverlay
      textColor={page.textColor}
      backgroundColor={page.backgroundColor}
      isFullScreen={isFullScreen}
    >
      <ErrorImageContainer imageUrl={page.imageUrl} />
      <ErrorImageText>{page.title}</ErrorImageText>
      <ErrorText>{page.text}</ErrorText>
      {renderReportButton()}
    </ErrorImageOverlay>
  );
};
