export enum UserActionType {
  GOOGLE_SIGN_IN_START = 'GOOGLE_SIGN_IN_START',
  EMAIL_SIGN_IN_START = 'EMAIL_SIGN_IN_START',
  SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE = 'SIGN_IN_FAILURE',
  REAUTHENTICATE_CURRENT_USER = 'REAUTHENTICATE_CURRENT_USER',
  SIGN_OUT_START = 'SIGN_OUT_START',
  SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS',
  SIGN_OUT_FAILURE = 'SIGN_OUT_FAILURE',
  SIGN_UP_START = 'SIGN_UP_START',
  SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS',
  SIGN_UP_FAILURE = 'SIGN_UP_FAILURE',
}

// ======================================================
// Entities
// ======================================================

export interface IUser {
  id: string;
  email: string;
  displayName: string;
  createdAt: {
    seconds: number;
    nanoseconds: number;
  };
  messengerPSID: string;
}

// ======================================================
// Action payload definitions
// ======================================================

export interface EmailSignInStartPayload {
  email: string;
  password: string;
}

export interface SignInSuccessPayload {
  user: IUser;
}

export interface SignUpStartPayload {
  email: string;
  password: string;
  displayName: string;
}

export interface SignUpSuccessPayload {
  uid: string;
  email: string;
  displayName: string;
}

export interface ErrorPayload {
  error: IError;
}

// ======================================================
// Saga payload wrapper definitions
// ======================================================

export interface SignUpPayloadWrapper {
  payload: SignUpStartPayload;
}

export interface SignWithEmailPayloadWrapper {
  payload: EmailSignInStartPayload;
}

export interface SignInAfterSignUpPayloadWrapper {
  payload: SignUpSuccessPayload;
}

// ======================================================
// Common
// ======================================================

export type IError = any;

export interface IError2 {
  code: string;
  message: string;
}

export interface IErrorArgs {
  error: IError;
}
