/*
 *  Basically, what this hook does is that, it takes a parameter with value true
 *  or false and toggles that value to opposite. It's useful when we want to take
 *  some action into it's opposite action, for example: show and hide modal,
 *  show more/show less text, open/close side menu.
 *
 *  Usage:
 *    function App() {
 *      // Call the hook which returns, current value and the toggler function
 *      const [isTextChanged, setIsTextChanged] = useToggle();
 *      return (
 *        <button onClick={setIsTextChanged}>{isTextChanged ? 'Toggled' : 'Click to Toggle'}</button>
 *      );
 *    }
 */

import { useCallback, useState } from 'react';

// Parameter is the boolean, with default "false" value
export function useToggle(initialState: boolean = false): [boolean, any] {
  // Initialize the state
  const [state, setState] = useState<boolean>(initialState);

  // Define and memorize toggler function in case we pass down the component,
  // This function change the boolean value to it's opposite value
  const toggle = useCallback((): void => setState((state) => !state), []);
  return [state, toggle];
}
