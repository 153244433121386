import React from 'react';
import useStyles from './LoadingScreen.styles';
import risingSunImage from 'assets/img/rising-sun.png';
import { SpinKit } from '../SpinKit/SpinKit.component';

export interface LoadingScreenProps {
  message?: string;
  image?: string;
}

const defaultMessage = 'Take a moment, the sun is rising...';

export function LoadingScreen({
  message = defaultMessage,
  image = risingSunImage,
}: LoadingScreenProps) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <img src={image} alt="..." />
      <div className={classes.text}>{message}</div>
      <div className={classes.spinner}>
        <SpinKit variant="flow" color="#999" />
      </div>
    </div>
  );
}
