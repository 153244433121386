import type { PayloadAction } from '@reduxjs/toolkit';

import {
  UserActionType,
  EmailSignInStartPayload,
  SignInSuccessPayload,
  SignUpSuccessPayload,
  SignUpStartPayload,
  IErrorArgs,
} from './user.types';

export const googleSignInStart = (): PayloadAction<null, UserActionType> => ({
  type: UserActionType.GOOGLE_SIGN_IN_START,
  payload: null,
});

export const emailSignInStart = ({
  email,
  password,
}: EmailSignInStartPayload): PayloadAction<EmailSignInStartPayload, UserActionType> => ({
  type: UserActionType.EMAIL_SIGN_IN_START,
  payload: { email, password },
});

export const signInSuccess = ({
  user,
}: SignInSuccessPayload): PayloadAction<SignInSuccessPayload, UserActionType> => ({
  type: UserActionType.SIGN_IN_SUCCESS,
  payload: { user },
});

export const signInFailure = ({
  error,
}: IErrorArgs): PayloadAction<IErrorArgs, UserActionType> => ({
  type: UserActionType.SIGN_IN_FAILURE,
  payload: { error },
});

export const reauthenticateCurrentUser = (): PayloadAction<null, UserActionType> => ({
  type: UserActionType.REAUTHENTICATE_CURRENT_USER,
  payload: null,
});

export const signOutStart = (): PayloadAction<null, UserActionType> => ({
  type: UserActionType.SIGN_OUT_START,
  payload: null,
});

export const signOutSuccess = (): PayloadAction<null, UserActionType> => ({
  type: UserActionType.SIGN_OUT_SUCCESS,
  payload: null,
});

export const signOutFailure = ({
  error,
}: IErrorArgs): PayloadAction<IErrorArgs, UserActionType> => ({
  type: UserActionType.SIGN_OUT_FAILURE,
  payload: { error },
});

export const signUpStart = ({
  email,
  password,
  displayName,
}: SignUpStartPayload): PayloadAction<SignUpStartPayload, UserActionType> => ({
  type: UserActionType.SIGN_UP_START,
  payload: { email, password, displayName },
});

export const signUpSuccess = ({
  uid,
  email,
  displayName,
}: SignUpSuccessPayload): PayloadAction<SignUpSuccessPayload, UserActionType> => ({
  type: UserActionType.SIGN_UP_SUCCESS,
  payload: { uid, email, displayName },
});

export const signUpFailure = ({
  error,
}: IErrorArgs): PayloadAction<IErrorArgs, UserActionType> => ({
  type: UserActionType.SIGN_UP_FAILURE,
  payload: { error },
});
