import React from 'react';
import classNames from 'classnames';

import { FormControl, InputLabel, FormHelperText, Input } from '@mui/material';
import { InputProps } from '@mui/material/Input';

import useStyles from './CustomInput.styles';

export type CustomInputProps = {
  labelText?: any;
  labelProps?: any;
  id?: string;
  inputProps?: InputProps;
  formControlProps?: any;
  inputRootCustomClasses?: string;
  error?: boolean;
  success?: boolean;
  white?: boolean;
  helperText?: any;
  rtlMode?: boolean;
};

export const InputText = React.forwardRef(
  (
    {
      formControlProps,
      labelText,
      id,
      labelProps,
      inputProps,
      error,
      white,
      inputRootCustomClasses,
      success,
      helperText,
    }: CustomInputProps,
    ref,
  ): JSX.Element => {
    const classes = useStyles();
    const labelClasses = classNames({
      [' ' + classes.labelRootError]: error,
      [' ' + classes.labelRootSuccess]: success && !error,
    });
    const underlineClasses = classNames({
      [classes.underlineError]: error,
      [classes.underlineSuccess]: success && !error,
      [classes.underline]: true,
      [classes.whiteUnderline]: white,
    });
    const marginTop = classNames({
      [inputRootCustomClasses ? inputRootCustomClasses : '']: inputRootCustomClasses !== undefined,
    });
    const inputClasses = classNames({
      [classes.input]: true,
      [classes.whiteInput]: white,
    });
    var formControlClasses;
    if (formControlProps !== undefined) {
      formControlClasses = classNames(formControlProps.className, classes.formControl);
    } else {
      formControlClasses = classes.formControl;
    }
    var helpTextClasses = classNames({
      [classes.labelRootError]: error,
      [classes.labelRootSuccess]: success && !error,
    });
    return (
      <FormControl {...formControlProps} className={formControlClasses}>
        {labelText !== undefined ? (
          <InputLabel
            className={classes.labelRoot + ' ' + labelClasses}
            htmlFor={id}
            {...labelProps}
          >
            {labelText}
          </InputLabel>
        ) : null}
        <Input
          // getInputRef={ref}
          inputRef={ref}
          classes={{
            input: inputClasses,
            root: marginTop,
            disabled: classes.disabled,
            underline: underlineClasses,
          }}
          id={id}
          {...inputProps}
        />
        {helperText !== undefined ? (
          <FormHelperText id={id + '-text'} className={helpTextClasses}>
            {helperText}
          </FormHelperText>
        ) : null}
      </FormControl>
    );
  },
);

export function CustomInput({
  formControlProps,
  labelText,
  id,
  labelProps,
  inputProps,
  error,
  white,
  inputRootCustomClasses,
  success,
  helperText,
}: CustomInputProps): JSX.Element {
  const classes = useStyles();
  const labelClasses = classNames({
    [' ' + classes.labelRootError]: error,
    [' ' + classes.labelRootSuccess]: success && !error,
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white,
  });
  const marginTop = classNames({
    [inputRootCustomClasses ? inputRootCustomClasses : '']: inputRootCustomClasses !== undefined,
  });
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white,
  });
  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(formControlProps.className, classes.formControl);
  } else {
    formControlClasses = classes.formControl;
  }
  var helpTextClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error,
  });
  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      {labelText !== undefined ? (
        <InputLabel className={classes.labelRoot + ' ' + labelClasses} htmlFor={id} {...labelProps}>
          {labelText}
        </InputLabel>
      ) : null}
      <Input
        classes={{
          input: inputClasses,
          root: marginTop,
          disabled: classes.disabled,
          underline: underlineClasses,
        }}
        id={id}
        {...inputProps}
      />
      {helperText !== undefined ? (
        <FormHelperText id={id + '-text'} className={helpTextClasses}>
          {helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}
