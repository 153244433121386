import React from 'react';
import classNames from 'classnames';
import { Button as MuiButton, ButtonClassKey } from '@mui/material';
import { ClassNameMap } from '@mui/styles';
import { ButtonColor } from '../styles/common.types';
import useStyles from './Button.styles';

export interface ButtonProps
  extends Omit<React.ComponentPropsWithRef<'button'>, 'color' | 'onClick'> {
  className?: string;
  muiClasses?: Partial<ClassNameMap<ButtonClassKey>>;
  children: any;
  size?: 'sm' | 'lg';
  color?: ButtonColor;
  round?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  simple?: boolean;
  block?: boolean;
  link?: boolean;
  justIcon?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  href?: string | undefined;
  buttonRef?: any;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      color,
      round,
      children,
      fullWidth,
      disabled,
      simple,
      size,
      block,
      link,
      justIcon,
      className,
      muiClasses,
      onClick,
      href,
      ...rest
    },
    ref,
  ): JSX.Element => {
    const classes = useStyles();

    const buttonClasses = classNames({
      [classes.button]: true,
      [size ? classes[size] : '']: size,
      [color ? classes[color] : '']: color,
      [classes.round]: round,
      [classes.fullWidth]: fullWidth,
      [classes.disabled]: disabled,
      [classes.simple]: simple,
      [classes.block]: block,
      [classes.link]: link,
      [classes.justIcon]: justIcon,
      [className ? className : '']: className,
    });

    return (
      // @ts-ignore
      <MuiButton
        {...rest}
        ref={ref}
        classes={muiClasses}
        className={buttonClasses}
        onClick={onClick}
        href={href}
      >
        {children}
      </MuiButton>
    );
  },
);
