import { makeStyles } from '@mui/styles';

export default makeStyles({
  container: {
    margin: '0 30px',
    color: 'red',
    display: 'flex',
    alignItems: 'center',

    '& div': {
      padding: '0 15px',
    },
  },
});
