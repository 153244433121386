import React from 'react';
import classNames from 'classnames';
import { MainColors } from '../styles/common.types';
import useStyles from './Card.styles';

export type CardProps = {
  className?: string;
  children?: any;
  plain?: boolean;
  profile?: boolean;
  blog?: boolean;
  raised?: boolean;
  background?: boolean;
  pricing?: boolean;
  color?: MainColors;
  product?: boolean;
  testimonial?: boolean;
  chart?: boolean;
  login?: boolean;
};

export function Card({
  className,
  children,
  plain,
  profile,
  blog,
  raised,
  background,
  pricing,
  color,
  product,
  testimonial,
  chart,
  login,
  ...rest
}: CardProps): JSX.Element {
  const classes = useStyles();

  const cardClasses = classNames({
    [classes.card]: true,
    [classes.cardPlain]: plain,
    [classes.cardProfile]: profile || testimonial,
    [classes.cardBlog]: blog,
    [classes.cardRaised]: raised,
    [classes.cardBackground]: background,
    [classes.cardPricingColor]:
      (pricing && color !== undefined) || (pricing && background !== undefined),
    [color ? color : '']: color as string,
    [classes.cardPricing]: pricing,
    [classes.cardProduct]: product,
    [classes.cardChart]: chart,
    [classes.cardLogin]: login,
    [className ? className : '']: className !== undefined,
  });

  return (
    <div className={cardClasses} {...rest}>
      {children}
    </div>
  );
}
