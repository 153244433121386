import { makeStyles } from '@mui/styles';

import { grayColor } from 'components/styles/common.styles';

export default makeStyles({
  heading: {
    marginBottom: '30px',
  },
  rightTextAlign: {
    textAlign: 'right',
  },
  leftTextAlign: {
    textAlign: 'left',
  },
  centerTextAlign: {
    textAlign: 'center',
  },
  title: {
    marginTop: '10px',
    color: grayColor[2],
    textDecoration: 'none',
  },
  category: {
    margin: '0 0 10px',
  },
});
