import React, { useState, useCallback } from 'react';
import { useDebounce } from 'hooks';

import { SidebarComponent, SidebarComponentProps } from './Sidebar.component';

export interface SidebarProps
  extends Omit<
    SidebarComponentProps,
    | 'onMouseOverDrawer'
    | 'onMouseOutDrawer'
    | 'onMouseOverHeader'
    | 'onMouseOutHeader'
    | 'showNavbarLink'
  > {}

export const Sidebar = React.memo(
  ({
    routes,
    open,
    miniMode,
    rtlMode,
    userName,
    logoText,
    logoImage,
    sidebarImage,
    highlightColor = 'blue',
    backgroundColor = 'black',
    onDrawerToggle,
    onSidebarToggle,
    onLogout,
  }: SidebarProps): JSX.Element => {
    const [miniModeActive, setMiniModeActive] = useState(miniMode);
    const [mouseHover, setMouseHover] = useState(false);

    const debouncedMiniModeActive = useDebounce(miniModeActive, 10);
    const debouncedMouseHover = useDebounce(mouseHover, 10);
    const debouncedMiniMode = miniMode && debouncedMiniModeActive;

    const handleSidebarToggle = useCallback(() => {
      onSidebarToggle();
      setMiniModeActive(!debouncedMiniMode);
    }, [debouncedMiniMode, onSidebarToggle, setMiniModeActive]);

    const handleMouseOverDrawer = useCallback(() => {
      setMiniModeActive(false);
      setMouseHover(true);
    }, []);

    const handleMouseOutDrawer = useCallback(() => {
      setMiniModeActive(true);
      setMouseHover(false);
    }, []);

    const handleMouseOverHeader = useCallback(
      (event: any) => {
        event.stopPropagation();
        if (debouncedMiniMode && !debouncedMouseHover) {
          setMiniModeActive(true);
          setMouseHover(false);
        } else {
          // if (debounceMiniMode && mouseHover) {
          setMiniModeActive(false);
          setMouseHover(true);
        }
      },
      [debouncedMiniMode, debouncedMouseHover, setMiniModeActive, setMouseHover],
    );

    const handleMouseOutHeader = useCallback(
      (event: any) => {
        event.stopPropagation();
        setMiniModeActive(true);
        setMouseHover(false);
      },
      [setMiniModeActive, setMouseHover],
    );

    return (
      <SidebarComponent
        routes={routes}
        open={open}
        miniMode={debouncedMiniMode}
        rtlMode={rtlMode}
        userName={userName}
        logoText={logoText}
        logoImage={logoImage}
        sidebarImage={sidebarImage}
        highlightColor={highlightColor}
        backgroundColor={backgroundColor}
        onDrawerToggle={onDrawerToggle}
        onSidebarToggle={handleSidebarToggle}
        onLogout={onLogout}
        onMouseOverDrawer={handleMouseOverDrawer}
        onMouseOutDrawer={handleMouseOutDrawer}
        onMouseOverHeader={handleMouseOverHeader}
        onMouseOutHeader={handleMouseOutHeader}
      />
    );
  },
);
