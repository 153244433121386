import React from 'react';
import { useAppDispatch } from 'redux/hooks';
import { signUpStart } from 'redux/user/user.actions';
import { RegisterPage } from './Register.component';

export function RegisterPageContainer() {
  const dispatch = useAppDispatch();

  const onSignUp = (displayName: string, email: string, password: string) =>
    dispatch(signUpStart({ displayName, email, password }));

  return <RegisterPage onSignUp={onSignUp} />;
}
