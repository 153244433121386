import React from 'react';
import cx from 'classnames';

import { Box, Drawer } from '@mui/material';
import { Content, ContentProps } from './partials';
import useStyles from './Sidebar.styles';

export interface SidebarComponentProps extends Omit<ContentProps, 'showNavbarLink'> {
  onMouseOverDrawer: any;
  onMouseOutDrawer: any;
}

export const SidebarComponent = React.memo(
  ({
    routes,
    open,
    miniMode,
    rtlMode,
    userName,
    logoText,
    logoImage,
    sidebarImage,
    highlightColor = 'blue',
    backgroundColor = 'black',
    onDrawerToggle,
    onSidebarToggle,
    onLogout,
    onMouseOverDrawer,
    onMouseOutDrawer,
    onMouseOverHeader,
    onMouseOutHeader,
  }: SidebarComponentProps): JSX.Element => {
    const classes = useStyles();

    const drawerPaperClass = cx(classes.drawerPaper, {
      [classes.drawerPaperMini]: miniMode,
      [classes.drawerPaperRTL]: rtlMode,
      [classes.blackBackground]: backgroundColor === 'black',
      [classes.blueBackground]: backgroundColor === 'blue',
      [classes.whiteBackground]: backgroundColor === 'white',
    });

    return (
      <div>
        {/* small screen */}
        <Box sx={{ display: { xs: 'block ', md: 'none' } }}>
          <Drawer
            classes={{ paper: drawerPaperClass }}
            variant="temporary"
            anchor={rtlMode ? 'left' : 'right'}
            open={open}
            onClose={onDrawerToggle}
            ModalProps={{ keepMounted: true }} // Better open performance on mobile.
          >
            <Content
              routes={routes}
              open={open}
              miniMode={miniMode}
              rtlMode={rtlMode}
              userName={userName}
              logoText={logoText}
              logoImage={logoImage}
              sidebarImage={sidebarImage}
              highlightColor={highlightColor}
              backgroundColor={backgroundColor}
              onDrawerToggle={onDrawerToggle}
              onSidebarToggle={onSidebarToggle}
              onLogout={onLogout}
              onMouseOverHeader={onMouseOverHeader}
              onMouseOutHeader={onMouseOutHeader}
              showNavbarLink
            />
          </Drawer>
        </Box>
        {/* large screen */}
        <Box sx={{ display: { xs: 'none', md: ' block' } }}>
          <Drawer
            classes={{ paper: drawerPaperClass }}
            variant="permanent"
            anchor={rtlMode ? 'right' : 'left'}
            open
            onMouseOver={onMouseOverDrawer}
            onMouseOut={onMouseOutDrawer}
          >
            <Content
              routes={routes}
              open={open}
              miniMode={miniMode}
              rtlMode={rtlMode}
              userName={userName}
              logoText={logoText}
              logoImage={logoImage}
              sidebarImage={sidebarImage}
              highlightColor={highlightColor}
              backgroundColor={backgroundColor}
              onDrawerToggle={onDrawerToggle}
              onSidebarToggle={onSidebarToggle}
              onLogout={onLogout}
              onMouseOverHeader={onMouseOverHeader}
              onMouseOutHeader={onMouseOutHeader}
              showNavbarLink={false}
            />
          </Drawer>
        </Box>
      </div>
    );
  },
);
