import { brokenPages, lostPages } from './ErrorPage.data';
import { ErrorPageType, ErrorPageData } from './ErrorPage.types';

export const selectRandomErrorPage = (type: ErrorPageType): ErrorPageData => {
  const pages = type === 'broken' ? brokenPages : lostPages;
  const index = Math.floor(Math.random() * pages.length);
  const selectedPage = brokenPages[index];
  selectedPage.title = selectedPage.title.replace('Page', 'App');
  return selectedPage;
};
